import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAudio } from "../../../../AudioContext";
import { PDFDocument, rgb } from "pdf-lib";
import { toast } from "react-toastify";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import {
  MdOutlineDownloadForOffline,
  MdOutlineDownloading,
} from "react-icons/md";
import { useLanguage } from "../../../../LanguageContext";

import axios from "axios";
import JSZip from "jszip";

import "../../Dashboard.css";
import ProductItems from "./ProductItems";
import MyButton from "../../../CommonFolder/MyButton";
import SignatureCanvas from "react-signature-canvas";
import translations from "../../../../translations.json";
import BookLoader from "../../../CommonFolder/BookLoader";
import TermAmountCalulation from "./TermAmountCalulation";
import InputFiled from "../../../CommonFolder/FormStructure/InputField";
import validationRules from "../../../CommonFolder/FormStructure/validationRules.json";
import socket from "../../../../SocketContext";

const DashboardLeftSidebar = ({
  menuData,
  emiData,
  second_emiData,
  interestData,
  termData,
  second_termData,
  downData,
  onSaveMenu,
  ProductShowAll,
  IsDealTypeFind,
  canSignIndex,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [InputChecked, setInputChecked] = useState(false);
  const [Pdfloading, setPdfLoading] = useState(false);
  const [shareDisabled, setShareDisabled] = useState(true);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [contractPdfs, setContractPdfs] = useState([]);
  const [isSignaturePresent, setIsSignaturePresent] = useState(false);
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(true);
  const [SharePolicieDisable, setSharePolicieDisable] = useState(true);
  const [email, setEmail] = useState();
  const [ContractLoading, setContractLoading] = useState(true);
  const [ContractLoadingError, setContractLoadingError] = useState(false);
  const [ContractLoadingErrorMessage, setContractLoadingErrorMessage] =
    useState("");
  const [recommendedServices, setRecommendedServices] = useState([]);
  const [errors, setErrors] = useState({});
  const [DownloadZip, setDownloadZip] = useState(false);
  const [downloadingIndex, setDownloadingIndex] = useState(null);

  const [SignPageIndex, setSignPageIndex] = useState(1);
  const [IsDealType, setDealType] = useState(false);
  const [uploadedPdfLinks, setUploadedPdfLinks] = useState([]);
  const [lwtProductEditDisable, setLwtProductEditDisable] = useState(true);
  const [IsUploadWetSign, setIsUploadWetSign] = useState(false);
  const [signatureImages, setSignatureImages] = useState({
    CUSTOMER_SIGNATURE: null,
    COCUSTOMER_SIGNATURE: null,
  });

  const sigCanvas = useRef({});
  const fileInputRef = useRef(null);
  const usertoken = localStorage.getItem("token");
  const { id } = useParams();
  const { playClickSound } = useAudio();
  const { language } = useLanguage();
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);

  if (Array.isArray(menuData)) {
    menuData = menuData[0];
  }

  // useEffect(() => {
  //   console.log("menuData", menuData);
  // }, []);

  // click sound function *********

  const handleContractSubmit = async () => {
    setPdfLoading(true);
    const data = {
      deal_id: Dealid,
    };
    const menuResponse = await fetch(
      "https://api.menuverse.ai/fni_products/sent_contract",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (menuResponse.status) {
      const menuResponseJson = await menuResponse.json();

      const submittedProducts = menuResponseJson.data;

      if (submittedProducts) {
        // console.log("setContractPdfs", contractPdfs);
        const productArr = [];
        for (const pdfProduct of submittedProducts) {
          productArr.push(
            pdfProduct.EX1ContractResponse?.Products?.Product
              ? pdfProduct.EX1ContractResponse?.Products?.Product
              : pdfProduct
          );
        }

        setContractPdfs(productArr);

        setPdfLoading(false);
        setContractLoading(false);
      } else {
        const submittedError =
          menuResponseJson.data.EX1ContractResponse?.MsgHeader?.Message
            ?.Description;
        setContractLoadingError(true);
        setContractLoadingErrorMessage(submittedError);
      }
    }
  };

  // handlePdf download pdf one by one click on view button
  const handlePdfDownload = async (ContractNumber, ProductCode, index) => {
    setDownloadingIndex(index); // Set the downloading index for the loader

    const data = {
      deal_id: Dealid,
      contract_number: ContractNumber,
      product_code: ProductCode,
    };

    try {
      // Fetch PDF data
      const menuResponse = await fetch(
        "https://api.menuverse.ai/fni_products/get_pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${usertoken}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!menuResponse.ok) {
        toast.warn(translations[language].FailedFetchPDF);
        setDownloadingIndex(null);
        return;
      }

      const menuResponseJson = await menuResponse.json();
      const pdfUrl =
        menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.ContractForm;
      const pagesData = Array.isArray(
        menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
          ?.Pages?.Page
      )
        ? menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
            ?.Pages?.Page
        : [
            menuResponseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
              ?.Pages?.Page,
          ];

      if (!pdfUrl || !signatureImages) {
        toast.warn(translations[language].PdfSignImgNotFound);
        setDownloadingIndex(null);
        return;
      }

      // Fetch and load the PDF
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const totalPages = pdfDoc.getPageCount();

      // Embed images dynamically based on format
      const embedImage = async (base64, pdfDoc) => {
        if (!base64) return null;
        const mimeType = base64.match(/^data:(image\/\w+);base64,/)?.[1];
        const imageBytes = Uint8Array.from(
          atob(base64.replace(/^data:image\/\w+;base64,/, "")),
          (c) => c.charCodeAt(0)
        );

        if (mimeType === "image/png") {
          return await pdfDoc.embedPng(imageBytes);
        } else if (mimeType === "image/jpeg" || mimeType === "image/jpg") {
          return await pdfDoc.embedJpg(imageBytes);
        }
        return null;
      };

      // Decode and embed all signatures
      const customerSignEmbed = await embedImage(
        signatureImages.CUSTOMER_SIGNATURE,
        pdfDoc
      );
      const coCustomerSignEmbed = await embedImage(
        signatureImages.COCUSTOMER_SIGNATURE,
        pdfDoc
      );
      const dealerSignBase64 = localStorage.getItem("dealer_sign");
      const dealerSignResponse = await fetch(dealerSignBase64);
      if (!dealerSignResponse.ok) {
        toast.warn(translations[language].DealerSignatureNotRetrieved);

        return;
      }
      // Convert response to ArrayBuffer and embed the image
      const dealerSignMimeType = dealerSignResponse.headers.get("Content-Type");

      if (
        !["image/png", "image/jpeg", "image/jpg"].includes(dealerSignMimeType)
      ) {
        toast.warn(translations[language].UnsupportedDealerSignatureFormat);
        setDownloadingIndex(null);

        return;
      }

      if (!dealerSignBase64) {
        toast.warn(
          translations[language].DealerSignatureNotFoundInLocalStorage
        );
        setDownloadingIndex(null);
        return;
      }

      // Decode and embed the dealer signature
      const dealerSignEmbed = await embedImage(dealerSignBase64, pdfDoc);
      if (!dealerSignEmbed) {
        toast.warn(translations[language].FailedToEmbedDealerSignature);
        setDownloadingIndex(null);
        return;
      }
      // Add signatures to the PDF
      for (const pageData of pagesData) {
        const PageNo = Number(pageData?.PageNo);
        const pageIndex = Math.min(Math.max(PageNo - 1, 0), totalPages - 1);
        const selectedPage = pdfDoc.getPage(pageIndex);
        const PageHeight = Number(pageData?.PageHeight);

        for (const coord of pageData?.Coords?.Coord || []) {
          const xPosition = Number(coord?.XPosition);
          const yPosition = Number(coord?.YPosition);
          const SignWidth = Number(coord?.Width);
          const SignHeight = Number(coord?.Height);
          const Pyposition = (PageHeight - yPosition) * 3 - 33 + 15;
          const Pxposition = xPosition * 3;
          const SignType = coord?.SigType;

          // Draw respective signature
          if (SignType === "CUSTOMER_SIGNATURE" && customerSignEmbed) {
            selectedPage.drawImage(customerSignEmbed, {
              x: Pxposition,
              y: Pyposition,
              width: SignWidth,
              height: SignHeight * 3,
            });
          } else if (
            SignType === "COCUSTOMER_SIGNATURE" &&
            coCustomerSignEmbed
          ) {
            selectedPage.drawImage(coCustomerSignEmbed, {
              x: Pxposition,
              y: Pyposition,
              width: SignWidth,
              height: SignHeight * 3,
            });
          } else if (SignType === "DEALER_SIGNATURE" && dealerSignEmbed) {
            selectedPage.drawImage(dealerSignEmbed, {
              x: Pxposition,
              y: Pyposition,
              width: SignWidth,
              height: SignHeight * 3,
            });
          } else if (
            canSignIndex === 1 &&
            (SignType === "COCUSTOMER_SIGNATURE" ||
              SignType === "CUSTOMER_SIGNATURE")
          ) {
            selectedPage.drawImage(customerSignEmbed, {
              x: Pxposition,
              y: Pyposition,
              width: SignWidth,
              height: SignHeight * 3,
            });
          }
        }
      }

      // Save and download the updated PDF
      const pdfWithSignatureBytes = await pdfDoc.save();
      const blob = new Blob([pdfWithSignatureBytes], {
        type: "application/pdf",
      });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "signed_contract.pdf";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadingIndex(null);
    } catch (error) {
      console.error("Error processing the PDF", error);
      toast.warn(translations[language].FailedToProcessPDF);
      setDownloadingIndex(null);
    }
  };

  // handle Download Zip click on download zip button click
  const handleDownloadZip = async () => {
    setDownloadZip(true);

    const embedImage = async (base64, pdfDoc) => {
      if (!base64) return null;
      const mimeType = base64.match(/^data:(image\/\w+);base64,/)?.[1];
      const imageBytes = Uint8Array.from(
        atob(base64.replace(/^data:image\/\w+;base64,/, "")),
        (c) => c.charCodeAt(0)
      );

      if (mimeType === "image/png") {
        return await pdfDoc.embedPng(imageBytes);
      } else if (mimeType === "image/jpeg" || mimeType === "image/jpg") {
        return await pdfDoc.embedJpg(imageBytes);
      }
      return null;
    };

    try {
      const zip = new JSZip();
      const folder = zip.folder("pdfs"); // Create a folder inside the zip for PDFs

      for (const pdf of contractPdfs) {
        const { ContractNumber, Product, Description } = pdf;
        const { ProductCode } = Product;
        if (Product) {
          const data = {
            deal_id: Dealid,
            contract_number: ContractNumber,
            product_code: ProductCode,
          };

          const response = await fetch(
            "https://api.menuverse.ai/fni_products/get_pdf",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${usertoken}`,
              },
              body: JSON.stringify(data),
            }
          );

          if (response.ok) {
            const responseJson = await response.json();
            const pdfUrl =
              responseJson.data.EX1FormResponse?.Contracts?.Product
                ?.ContractForm;
            const pagesData = Array.isArray(
              responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                ?.Pages?.Page
            )
              ? responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                  ?.Pages?.Page
              : [
                  responseJson.data.EX1FormResponse?.Contracts?.Product
                    ?.SigCoords?.Pages?.Page,
                ];

            if (pdfUrl && signatureImages) {
              const pdfBytes = await fetch(pdfUrl).then((res) =>
                res.arrayBuffer()
              );
              const pdfDoc = await PDFDocument.load(pdfBytes);
              const totalPages = pdfDoc.getPageCount();

              const customerSignEmbed = await embedImage(
                signatureImages.CUSTOMER_SIGNATURE,
                pdfDoc
              );
              const coCustomerSignEmbed = await embedImage(
                signatureImages.COCUSTOMER_SIGNATURE,
                pdfDoc
              );

              const dealerSignBase64 = localStorage.getItem("dealer_sign");
              const dealerSignEmbed = dealerSignBase64
                ? await embedImage(dealerSignBase64, pdfDoc)
                : null;

              for (const pageData of pagesData) {
                const PageNo = Number(pageData?.PageNo);
                const pageIndex = Math.min(
                  Math.max(PageNo - 1, 0),
                  totalPages - 1
                );
                const selectedPage = pdfDoc.getPage(pageIndex);
                const PageHeight = Number(pageData?.PageHeight);

                for (const coord of pageData?.Coords?.Coord || []) {
                  const xPosition = Number(coord?.XPosition);
                  const yPosition = Number(coord?.YPosition);
                  const SignWidth = Number(coord?.Width);
                  const SignHeight = Number(coord?.Height);
                  const Pyposition = (PageHeight - yPosition) * 3 - 33 + 15;
                  const Pxposition = xPosition * 3;
                  const SignType = coord?.SigType;

                  if (SignType === "CUSTOMER_SIGNATURE" && customerSignEmbed) {
                    selectedPage.drawImage(customerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    SignType === "COCUSTOMER_SIGNATURE" &&
                    coCustomerSignEmbed
                  ) {
                    selectedPage.drawImage(coCustomerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    SignType === "DEALER_SIGNATURE" &&
                    dealerSignEmbed
                  ) {
                    selectedPage.drawImage(dealerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    canSignIndex === 1 &&
                    (SignType === "COCUSTOMER_SIGNATURE" ||
                      SignType === "CUSTOMER_SIGNATURE")
                  ) {
                    selectedPage.drawImage(customerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  }
                }
              }

              const pdfWithSignatureBytes = await pdfDoc.save();
              const pdfBlob = new Blob([pdfWithSignatureBytes], {
                type: "application/pdf",
              });
              folder.file(`${ContractNumber}_${ProductCode}.pdf`, pdfBlob);
            }
          }
        }
      }

      const zipBlob = await zip.generateAsync({ type: "blob" });
      const zipUrl = URL.createObjectURL(zipBlob);
      const link = document.createElement("a");
      link.href = zipUrl;
      link.download = "contracts.zip";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setDownloadZip(false);
    } catch (error) {
      console.error("Error downloading zip", error);
      toast.warn(translations[language].FailedToDownloadZip);

      setDownloadZip(false);
    }
  };

  const handleUploadMergedPdfs = async () => {
    const embedImage = async (base64, pdfDoc) => {
      if (!base64) return null;
      const mimeType = base64.match(/^data:(image\/\w+);base64,/)?.[1];
      const imageBytes = Uint8Array.from(
        atob(base64.replace(/^data:image\/\w+;base64,/, "")),
        (c) => c.charCodeAt(0)
      );

      if (mimeType === "image/png") {
        return await pdfDoc.embedPng(imageBytes);
      } else if (mimeType === "image/jpeg" || mimeType === "image/jpg") {
        return await pdfDoc.embedJpg(imageBytes);
      }
      return null;
    };

    try {
      setUploadedCount(0);
      for (const [index, pdf] of contractPdfs.entries()) {
        const { ContractNumber, Product } = pdf;
        const { ProductCode } = Product;

        if (Product) {
          const data = {
            deal_id: Dealid,
            contract_number: ContractNumber,
            product_code: ProductCode,
          };

          const response = await fetch(
            "https://api.menuverse.ai/fni_products/get_pdf",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${usertoken}`,
              },
              body: JSON.stringify(data),
            }
          );

          if (response.ok) {
            const responseJson = await response.json();
            const pdfUrl =
              responseJson.data.EX1FormResponse?.Contracts?.Product
                ?.ContractForm;
            const pagesData = Array.isArray(
              responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                ?.Pages?.Page
            )
              ? responseJson.data.EX1FormResponse?.Contracts?.Product?.SigCoords
                  ?.Pages?.Page
              : [
                  responseJson.data.EX1FormResponse?.Contracts?.Product
                    ?.SigCoords?.Pages?.Page,
                ];

            if (pdfUrl && signatureImages) {
              const pdfBytes = await fetch(pdfUrl).then((res) =>
                res.arrayBuffer()
              );
              const pdfDoc = await PDFDocument.load(pdfBytes);
              const totalPages = pdfDoc.getPageCount();

              const customerSignEmbed = await embedImage(
                signatureImages.CUSTOMER_SIGNATURE,
                pdfDoc
              );
              const coCustomerSignEmbed = await embedImage(
                signatureImages.COCUSTOMER_SIGNATURE,
                pdfDoc
              );

              const dealerSignBase64 = localStorage.getItem("dealer_sign");
              const dealerSignEmbed = dealerSignBase64
                ? await embedImage(dealerSignBase64, pdfDoc)
                : null;

              for (const pageData of pagesData) {
                const PageNo = Number(pageData?.PageNo);
                const pageIndex = Math.min(
                  Math.max(PageNo - 1, 0),
                  totalPages - 1
                );
                const selectedPage = pdfDoc.getPage(pageIndex);
                const PageHeight = Number(pageData?.PageHeight);

                for (const coord of pageData?.Coords?.Coord || []) {
                  const xPosition = Number(coord?.XPosition);
                  const yPosition = Number(coord?.YPosition);
                  const SignWidth = Number(coord?.Width);
                  const SignHeight = Number(coord?.Height);
                  const Pyposition = (PageHeight - yPosition) * 3 - 33 + 15;
                  const Pxposition = xPosition * 3;
                  const SignType = coord?.SigType;

                  if (SignType === "CUSTOMER_SIGNATURE" && customerSignEmbed) {
                    selectedPage.drawImage(customerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    SignType === "COCUSTOMER_SIGNATURE" &&
                    coCustomerSignEmbed
                  ) {
                    selectedPage.drawImage(coCustomerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    SignType === "DEALER_SIGNATURE" &&
                    dealerSignEmbed
                  ) {
                    selectedPage.drawImage(dealerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  } else if (
                    canSignIndex === 1 &&
                    (SignType === "COCUSTOMER_SIGNATURE" ||
                      SignType === "CUSTOMER_SIGNATURE")
                  ) {
                    selectedPage.drawImage(customerSignEmbed, {
                      x: Pxposition,
                      y: Pyposition,
                      width: SignWidth,
                      height: SignHeight * 3,
                    });
                  }
                }
              }

              const pdfWithSignatureBytes = await pdfDoc.save();
              const pdfBlob = new Blob([pdfWithSignatureBytes], {
                type: "application/pdf",
              });

              // Prepare the form data to upload the PDF
              const formData = new FormData();
              formData.append(
                "pdf",
                pdfBlob,
                `${ContractNumber}_${ProductCode}.pdf`
              );

              formData.append("deal_id", Dealid);
              formData.append("product", ProductCode);

              try {
                const uploadResponse = await fetch(
                  "https://api.menuverse.ai/sign/upload",
                  {
                    method: "POST",
                    headers: {
                      authorization: `Bearer ${usertoken}`,
                    },
                    body: formData,
                  }
                );

                if (uploadResponse.ok) {
                  const responseData = await uploadResponse.json();
                  const pdfLink = responseData.data.pdfname;
                  // console.log("Upload Successful Response:", pdfLink);

                  // Store PDF link
                  setUploadedPdfLinks((prevLinks) => [...prevLinks, pdfLink]);
                } else {
                  const errorResponse = await uploadResponse.text();
                  console.error("Upload Failed Response:", errorResponse);
                }

                // // Store PDF link
                // setUploadedPdfLinks((prevLinks) => [...prevLinks, pdfLink]);

                // Increment the uploaded count
                setUploadedCount((prevCount) => prevCount + 1);
              } catch (error) {
                // const errorResponse = await uploadResponse.text();
                // console.error("Upload Failed Response:", errorResponse);
              }
            }
          }
        }
      }
      setShareDisabled(false);
      // toast.success("All PDFs processed.");
    } catch (error) {
      console.error("Error uploading PDFs", error);
      // toast.error("Failed to upload PDFs");
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const InputCheckboxChange = () => {
    setInputChecked((prev) => !prev);
  };

  const clearSignature = () => {
    setInputChecked(false);
    if (IsUploadWetSign) {
      setIsUploadWetSign(false);
      // setSignatureImages((prev) => ({
      //   ...prev,
      //   CUSTOMER_SIGNATURE: null,
      //   COCUSTOMER_SIGNATURE: null,
      // }));
    } else {
      sigCanvas.current?.clear();
      setIsSignaturePresent(false);
    }
  };

  const checkSignature = () => {
    // Check if the signature canvas is empty
    setIsSignaturePresent(!sigCanvas.current.isEmpty());
  };

  // to get first sign *******
  const NextSignHandle = () => {
    if (
      (sigCanvas.current && !sigCanvas.current.isEmpty()) ||
      IsUploadWetSign
    ) {
      if (!IsUploadWetSign) {
        const dataURL = sigCanvas.current
          .getTrimmedCanvas()
          .toDataURL("image/png");
        setSignatureImages((prev) => {
          const updatedImages = {
            ...prev,
            CUSTOMER_SIGNATURE: dataURL,
          };
          console.warn(
            "Updated CUSTOMER_SIGNATURE:",
            updatedImages.CUSTOMER_SIGNATURE
          );
          return updatedImages;
        });
      }
      setSignPageIndex(2);
      setInputChecked(false);
      clearSignature();
      setIsSignaturePresent(false);
    } else {
      console.error("Canvas is either empty or not initialized");
    }
  };

  // to get last sign**********
  const handleAcceptSign = () => {
    if (sigCanvas.current && !sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      if (canSignIndex === 2) {
        setSignatureImages((prev) => ({
          ...prev,
          COCUSTOMER_SIGNATURE: dataURL,
        }));
        console.warn("handleAcceptSign Data URL:", dataURL);
      } else {
        setSignatureImages((prev) => ({
          ...prev,
          CUSTOMER_SIGNATURE: dataURL,
        }));
      }
    } else {
      console.error("Canvas is either empty or not initialized");
    }
    sigCanvas.current.clear();
  };

  const closeEsignatureModal = () => {
    playClickSound();
    setSignPageIndex(1);
    clearSignature();
  };

  const handleWetSignClick = () => {
    // Trigger the file input click event
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    // Validate file type
    const validImageTypes = ["image/png", "image/jpeg", "image/jpg"];
    if (!validImageTypes.includes(file.type)) {
      console.error(
        "Invalid file type. Please upload an image (PNG, JPG, JPEG)."
      );
      toast.warn("Invalid file type. Please upload an image (PNG, JPG, JPEG).");
      return;
    }

    // Validate file size (e.g., max  MB)
    const maxSizeInMB = 2;
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
    if (file.size > maxSizeInBytes) {
      console.error(
        `File size exceeds ${maxSizeInMB} MB. Please upload a smaller file.`
      );
      toast.warn(
        `File size exceeds ${maxSizeInMB} MB. Please upload a smaller file.`
      );
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      if (reader.result) {
        const signatureType =
          SignPageIndex === 1 ? "CUSTOMER_SIGNATURE" : "COCUSTOMER_SIGNATURE";

        setSignatureImages((prev) => {
          const updatedImages = {
            ...prev,
            [signatureType]: reader.result,
          };
          // console.warn("Updated CUSTOMER_SIGNATURE:", updatedImages);
          return updatedImages;
        });
        sigCanvas.current?.clear();

        setIsUploadWetSign(true);
        setIsCanvasDisabled(true); // Disable canvas when an image is uploaded
        setIsSignaturePresent(true); // Mark signature as present
      } else {
        console.error("FileReader failed to read the file");
      }
    };
    reader.readAsDataURL(file); // Read the file as a data URL
  };

  const handleSaveMenu = () => {
    // alert("save");
    if (onSaveMenu) {
      // alert("save");
      onSaveMenu();
    }
  };
  const content =
    ProductShowAll === 1 ? (
      <div>
        <p>{translations[language].MakeItFullySecured}</p>
      </div>
    ) : ProductShowAll === 2 ? (
      <div>
        <p>{translations[language].UpgradeToBelowWarranties}</p>
      </div>
    ) : (
      <div>
        <p>{translations[language].Congratulations}!</p>
        <p>{translations[language].YourVehicleIsFullyProtected}</p>
      </div>
    );

  // Validate Email field
  const validateEmail = () => {
    const emailRules = validationRules.email;
    const emailError = {};

    // Validate email based on required and pattern rules
    if (emailRules.required) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(email)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    // Set the error state for display
    setErrors(emailError);

    // Return whether email is valid
    return Object.keys(emailError).length === 0;
  };

  // Handle changes in the email input
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);

    // console.warn("emailValue", emailValue);

    const emailRules = validationRules.email;
    const emailError = {};

    // Validate dynamically when typing
    if (emailRules.required && !emailValue) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(emailValue)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    // Update error state dynamically
    if (Object.keys(emailError).length === 0) {
      setErrors({}); // Clear errors
      setSharePolicieDisable(false); // Enable button
    } else {
      setSharePolicieDisable(true); // Disable button
    }
  };

  const fetchPdfData = async (contractNumber, productCode) => {
    try {
      const response = await fetch(`/api/pdf/${contractNumber}/${productCode}`);
      if (!response.ok) {
        throw new Error("Failed to fetch PDF data");
      }
      return await response.blob(); // Return the binary data of the PDF
    } catch (error) {
      console.error("Error fetching PDF data:", error);
      return null;
    }
  };

  // Handle "Share Now" button click
  const handleSharePolicy = async (e) => {
    const emailValue = e.target.value;

    const emailRules = validationRules.email;
    const emailError = {};
    // console.warn("emailRules", emailRules);
    // console.warn("emailValue11", emailValue);

    // Validate dynamically when typing
    if (emailRules.required && !emailValue) {
      emailError.email = emailRules.messages.required;
    } else if (
      emailRules.pattern &&
      !new RegExp(emailRules.pattern).test(emailValue)
    ) {
      emailError.email = emailRules.messages.pattern;
    }

    if (!validateEmail()) {
      setSharePolicieDisable(true);
      setErrors(emailError); // Set errors
      // Disable button if email is invalid
    } else {
      setSharePolicieDisable(false); // Enable button if validation passes
    }

    try {
      const attachments = await Promise.all(
        contractPdfs.map(async (pdf) => {
          if (pdf.Product) {
            const pdfData = await fetchPdfData(
              pdf.ContractNumber,
              pdf.Product.ProductCode
            );
            if (pdfData) {
              return {
                filename: `${pdf.Product.ProductName}-${pdf.ContractNumber}.pdf`,
                content: pdfData, // content should be file data here
              };
            }
          }

          return null;
        })
      );

      // Filter out null attachments
      const validAttachments = attachments.filter(
        (attachment) => attachment !== null
      );

      if (validAttachments.length > 0) {
        await sendEmail(validAttachments, emailValue);
      } else {
        console.error("No valid PDFs found to send");
      }
    } catch (error) {
      console.error("Error preparing email attachments:", error);
    }
  };

  // Function to send email using SMTP

  const sendEmail = async (contractForms, email) => {
    const formData = new FormData();
    const htmlContent = `
    <p>Dear Customer,</p>
    <p>Thank you for choosing our services. We are pleased to inform you that your policy documents have been successfully shared.</p>
    <p>Below are the links to access your contract forms:</p>
    <ul>
     ${uploadedPdfLinks
       .map((link, index) => {
         const filename =
           contractForms[index]?.filename || `Contract ${index + 1}`;
         return `<li>
            <a href="${link}" download>
              ${filename}
            </a>
          </li>`;
       })
       .join("")}
    </ul>
    <p>We recommend downloading and reviewing your contracts at your earliest convenience.</p>
    <p>Thank you for placing your trust in us. We look forward to serving you.</p>
    <p>Best regards</p>
  `;

    formData.append("to", email);
    formData.append("subject", "Policy Sharing");
    formData.append("html", htmlContent);

    const response = await fetch("https://api.menuverse.ai/email/send-email", {
      method: "POST",
      headers: {
        authorization: `Bearer ${usertoken}`,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Failed to send email");
    }

    return response.json();
  };

  // Fetch recommended_service names from API
  useEffect(() => {
    const fetchRecommendedServices = async () => {
      try {
        const response = await axios.get(
          `https://api.menuverse.ai/dealWarranties/show/${id}`,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + usertoken,
            },
          }
        );

        const recommendedServicesArray = response.data.data.map(
          (item) => item.name
        );
        // console.log("recommendedServicesArray", recommendedServicesArray);
        setRecommendedServices(recommendedServicesArray);
      } catch (error) {
        console.error("Error fetching recommended services:", error);
      }
    };

    fetchRecommendedServices();
  }, [menuData]);

  // Filter out empty strings and create a Set to remove duplicates
  const uniqueServices = [
    ...new Set(recommendedServices.filter((service) => service.trim() !== "")),
  ];
  const isProductRecommended = (title) => uniqueServices.includes(title);
  const updatedMenuData = Object.keys(menuData).filter(
    (product) => !isProductRecommended(product)
  );

  let disabledProductsTotalPrice = 0;
  updatedMenuData.forEach((key) => {
    if (menuData[key]) {
      if (menuData[key].is_deleted !== 1) {
        disabledProductsTotalPrice += parseFloat(menuData[key].price);
      }
    }
  });

  const [productCosts, setProductCosts] = useState({
    VSC: "",
    GAP: "",
    PPM: "",
    anti_theft: "",
    PDR: "",
    windshield_protection: "",
    TB: "",
    TWL: "",
    key_replacement: "",
    LWT: "",
  });

  useEffect(() => {
    // console.log("IsDealTypeFind.data?.menu.", IsDealTypeFind.data?.car_price);
    if (Array.isArray(IsDealTypeFind.data?.menu)) {
      const updatedCosts = { ...productCosts };

      IsDealTypeFind.data?.menu.forEach((item) => {
        // Check for VSC cost
        if (item.VSC?.[0]?.FandI_Cost) {
          updatedCosts.VSC = item.VSC[0].FandI_Cost;
        }

        // Check for GAP cost
        if (item.GAP?.[0]?.FandI_Cost) {
          updatedCosts.GAP = item.GAP[0].FandI_Cost;
        }

        // Check for PPM cost
        if (item.PPM?.[0]?.FandI_Cost) {
          updatedCosts.PPM = item.PPM[0].FandI_Cost;
        }

        // Check for Anti-theft cost
        if (item.anti_theft?.[0]?.FandI_Cost) {
          updatedCosts.anti_theft = item.anti_theft[0].FandI_Cost;
        }

        // Check for PDR cost
        if (item.PDR?.[0]?.FandI_Cost) {
          updatedCosts.PDR = item.PDR[0].FandI_Cost;
        }

        // Check for Windshield Protection cost
        if (item.windshield_protection?.[0]?.FandI_Cost) {
          updatedCosts.windshield_protection =
            item.windshield_protection[0].FandI_Cost;
        }

        // Check for TotalCase Bundle cost
        if (item.TB?.[0]?.FandI_Cost) {
          updatedCosts.TB = item.TB[0].FandI_Cost;
        }

        // Check for TWL cost
        if (item.TWL?.[0]?.FandI_Cost) {
          updatedCosts.TWL = item.TWL[0].FandI_Cost;
        }

        // Check for Key Replacement cost
        if (item.key_replacement?.[0]?.FandI_Cost) {
          updatedCosts.key_replacement = item.key_replacement[0].FandI_Cost;
        }

        // Check for LWT cost
        if (item.LWT?.[0]?.FandI_Cost) {
          updatedCosts.LWT = item.LWT[0].FandI_Cost;
        }
      });

      setProductCosts(updatedCosts);
    }
  }, [IsDealTypeFind]);

  const IsDeal_type = localStorage.getItem("IsDeal_type");
  useEffect(() => {
    if (IsDeal_type == "Cash") {
      // setDealType(false);
      setDealType(true);
    } else {
      setDealType(false);
      // setDealType(true);
    }
  }, []);

  // console.log("menuData ", menuData.VSC.is_deleted);
  // const handleButtonClick = () => {
  //   // Data to be sent with the socket event
  //   const notificationData = {
  //     userId: 123, // Example userId
  //     roleId: 1,
  //     title: "Deal Completed",
  //     message: "A deal is successfully completed by customer now you can proceed to further steps.",
  //   };

  //   console.log("Emitting addNotification:", notificationData);

  //   // Emit the socket event
  //   if (socket.connected) {
  //     socket.emit("addNotification", notificationData, (ack) => {
  //       if (ack?.success) {
  //         const { roleId } = notificationData;
  //       socket.emit("getadminNotifications", { roleId });
  //         console.log("Notification added successfully:", ack.message);
  //       } else {
  //         console.error("Error adding notification:", ack?.message || "No acknowledgment received.");
  //       }
  //     });
  //   } else {
  //     console.error("Socket not connected. Cannot emit event.");
  //   }
  // };

  const handleButtonClick = async () => {
    try {
      const url = window.location.href;
      const pathSegments = url.split("/");
      const id = pathSegments[pathSegments.length - 1];

      const token = localStorage.getItem("token");

      const response = await axios.get(
        `https://api.menuverse.ai/newdeal/show/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { dealer_id, business_manager, firstName } = response.data.data;
      const customer = firstName;
      console.log("dealer_id", dealer_id);
      console.log("business_manager", business_manager);
      console.log("customer", customer);
      console.log("Response Data:", response.data.data);

      const notificationData = {
        roleId: 1,
        title: "Deal Completed",
        message: `A deal is successfully completed by ${customer}. Now you can proceed to further steps.`,
      };

      // Emit notification to business manager
      console.log(
        "Emitting notification for business manager:",
        business_manager
      );
      socket.emit("addNotification", {
        ...notificationData,
        userId: business_manager,
      });

      // Emit notification to dealer
      console.log("Emitting notification for dealer:", dealer_id);
      socket.emit("addNotification", {
        ...notificationData,
        userId: dealer_id,
      });

      // Optionally fetch updated notifications if needed
      socket.emit("getadminNotifications", { roleId: 1 });
      console.log("getadminNotifications event emitted");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <>
      <div
        className={`col-lg-3 Dashboard-Left-Sidebar ${
          ProductShowAll === 1
            ? "Bg-Warning"
            : ProductShowAll === 2
            ? "Bg-Danger"
            : ""
        }`}
      >
        <div className="head">
          <div className="d-flex gap-2">
            {ProductShowAll === 1 ? (
              <img
                src="/assets/Images/DashboardImages/triangle-warning.svg"
                alt=""
              />
            ) : ProductShowAll >= 2 ? (
              <img
                src="/assets/Images/DashboardImages/triangle-danger.svg"
                alt=""
              />
            ) : ProductShowAll === 0 ? (
              <img
                src="/assets/Images/DashboardImages/checkVector.svg"
                alt=""
              />
            ) : (
              <img
                src="/assets/Images/DashboardImages/checkVector.svg"
                alt=""
              />
            )}

            <div>{content}</div>
          </div>
          <div className="Car_EmiAnd_Total_Price">
            {IsDealType
              ? IsDealTypeFind.data && (
                  <p>
                    {translations[language].carPrice}:
                    <span>${IsDealTypeFind.data?.car_price}</span>
                  </p>
                )
              : IsDealTypeFind.data && (
                  <>
                    <p>
                      {translations[language].Pymt}:{" "}
                      <span>${IsDealTypeFind.data?.car_emi}</span>
                    </p>
                    <p>
                      {translations[language].TermRate}:&nbsp;
                      <span>
                        {IsDealTypeFind.data?.term} /{" "}
                        {IsDealTypeFind.data?.interest}%
                      </span>
                    </p>
                  </>
                )}
          </div>
        </div>
        {/*========== here is start green sidebar ========== */}

        <div className="Product-Options">
          <div>
            <div className="Recommend_Select_Options">
              {!isChecked && (
                <p>{translations[language].ExploreOurVehicleProductOptions}</p>
              )}
              {isChecked && (
                <p>
                  <span>VERSA</span>{" "}
                  {
                    translations[language]
                      .recommendationsBasedOnYourCustomOwnershipJourney
                  }
                </p>
              )}
              <div className="Versa_Recommends">
                <div>
                  <span>VERSA</span>
                  <p>{translations[language].Recommends}</p>
                </div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    onClick={playClickSound}
                  />
                  <span className="custom-checkmark"></span>
                </label>
              </div>
            </div>
            <div
              className={`Product-Options-Items row row-cols-2  gy-3 ${
                IsDealType ? "CashDeal" : ""
              }`}
            >
              {menuData?.VSC && (
                <ProductItems
                  ProductItemsTitle="VSC"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.VSC
                      : menuData?.VSC?.price ?? "47.00"
                  }
                  ProuctImage="tools.svg"
                  productDetail={menuData?.VSC}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("VSC") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("VSC")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.VSC.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.GAP && (
                <ProductItems
                  ProductItemsTitle="GAP"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.GAP
                      : menuData?.GAP?.price ?? "26.54"
                  }
                  ProuctImage="car-crash.svg"
                  productDetail={menuData?.GAP}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("GAP") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("GAP")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.GAP.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.PPM && (
                <ProductItems
                  ProductItemsTitle="PPM"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.PPM
                      : menuData?.PPM?.price ?? "11.00"
                  }
                  ProuctImage="under-maintenance.svg"
                  productDetail={menuData?.PPM}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("PPM") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("PPM")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.PPM.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.anti_theft && (
                <ProductItems
                  ProductItemsTitle="Anti-theft"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.anti_theft
                      : menuData?.anti_theft?.price ?? "0.00"
                  }
                  ProuctImage="anti-theft-system.svg"
                  productDetail={menuData?.anti_theft}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Anti-theft") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("Anti-theft")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={
                    menuData.anti_theft.is_deleted == 1 ? "Deactive" : ""
                  }
                />
              )}

              {menuData?.PDR && (
                <ProductItems
                  ProuctImage="car-painting.svg"
                  ProductItemsTitle="PDR"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.PDR
                      : menuData?.PDR?.price ?? "14.00"
                  }
                  productDetail={menuData?.PDR}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("PDR") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("PDR")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.PDR.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.windshield_protection && (
                <ProductItems
                  ProuctImage="windshield.svg"
                  ProductItemsTitle="Windshield Protection"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.windshield_protection
                      : menuData?.windshield_protection?.price ?? "8.99"
                  }
                  productDetail={menuData?.windshield_protection}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Windshield Protection")
                      ? isChecked
                      : false
                  }
                  AcDeactive={
                    !isProductRecommended("Windshield Protection")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={
                    menuData.windshield_protection.is_deleted == 1
                      ? "Deactive"
                      : ""
                  }
                />
              )}

              {menuData?.TB && (
                <ProductItems
                  ProuctImage="layers.svg"
                  ProductItemsTitle="TotalCase Bundle"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.TB
                      : menuData?.TB?.price ?? "55.00"
                  }
                  productDetail={menuData?.TB}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("TotalCase Bundle") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("TotalCase Bundle")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.TB.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.TWL && (
                <ProductItems
                  ProuctImage="tierVector.svg"
                  ProductItemsTitle="TWL"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.TWL
                      : menuData?.TWL?.price ?? "12.00"
                  }
                  productDetail={menuData?.TWL}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={isProductRecommended("TWL") ? isChecked : false}
                  AcDeactive={
                    !isProductRecommended("TWL")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={menuData.TWL.is_deleted == 1 ? "Deactive" : ""}
                />
              )}

              {menuData?.key_replacement && (
                <ProductItems
                  ProuctImage="tools.svg"
                  ProductItemsTitle="Key-Replacement"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.key_replacement
                      : menuData?.key_replacement?.price ?? "6.00"
                  }
                  productDetail={menuData?.key_replacement}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  Option_Logo={
                    isProductRecommended("Key-Replacement") ? isChecked : false
                  }
                  AcDeactive={
                    !isProductRecommended("Key-Replacement")
                      ? isChecked
                        ? "Deactive"
                        : ""
                      : ""
                  }
                  DeleteProduct={
                    menuData.key_replacement.is_deleted == 1 ? "Deactive" : ""
                  }
                />
              )}

              {menuData?.LWT && (
                <ProductItems
                  ProuctImage="layers.svg"
                  ProductItemsTitle="LWT"
                  ProductItemsPrice={
                    IsDealType
                      ? productCosts.LWT
                      : menuData?.LWT?.price ?? "0.00"
                  }
                  productDetail={menuData?.LWT}
                  interestData={interestData}
                  termData={termData}
                  second_termData={second_termData}
                  onSaveMenu={handleSaveMenu}
                  AcDeactive={
                    !isProductRecommended("LWT") ? (isChecked ? "" : "") : ""
                  }
                  lwtProductEditDisable={lwtProductEditDisable}
                  DeleteProduct={menuData.LWT.is_deleted == 1 ? "Deactive" : ""}
                />
              )}
            </div>
          </div>
          <div className="Left_Sidebar_Footer">
            <div className="Term_Amount_Calulation">
              {!IsDealType ? (
                <>
                  <TermAmountCalulation
                    IsDealType={IsDealType}
                    emi={
                      isChecked
                        ? emiData - disabledProductsTotalPrice
                        : emiData ?? "1233"
                    }
                    interest={interestData ?? "8"}
                    term={termData ?? "72"}
                    down={downData ?? "0.00"}
                  />
                  <TermAmountCalulation
                    IsDealType={IsDealType}
                    emi={
                      isChecked
                        ? emiData - disabledProductsTotalPrice
                        : second_emiData ?? "780.90"
                    }
                    interest={interestData ?? "60"}
                    term={second_termData ?? "8"}
                    down={downData ?? "0.00"}
                  />
                </>
              ) : (
                <TermAmountCalulation
                  IsDealType={IsDealType}
                  emi={
                    IsDealTypeFind.data?.emi ? IsDealTypeFind.data?.emi : "0.00"
                  }
                  down={
                    IsDealTypeFind.data?.total_cost
                      ? IsDealTypeFind.data?.total_cost
                      : "0.00"
                  }
                />
              )}
            </div>
            <button
              className="Sign_And_Verify"
              data-bs-toggle="modal"
              data-bs-target="#ESignature"
              onClick={(e) => {
                playClickSound();
                handleButtonClick();
              }}
            >
              {translations[language].SignVerify}
            </button>
          </div>
        </div>
      </div>

      {/*========== here is start modal ESignature ========== */}

      <div
        className="modal fade"
        id="ESignature"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ESignatureMoadal">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeEsignatureModal();
                }}
              ></button>

              <img
                src="/assets/Images/DashboardImages/E-signatureimg.svg"
                alt=""
              />
              <h4>{translations[language].ESignature}</h4>
            </div>
            <div className="modal-body text-center p-0">
              <div className="ESignatureTextarea">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="black"
                  canvasProps={{
                    width: 500,
                    height: 200,
                    className: "sigCanvas",
                  }}
                  onEnd={checkSignature}
                  disabled={isCanvasDisabled}
                />

                <a onClick={clearSignature}>{translations[language].Clear}</a>

                {canSignIndex === 2 && (
                  <span className="SignNoIndex">
                    {SignPageIndex === 1 ? "1/2" : "2/2"}
                  </span>
                )}

                {IsUploadWetSign && (
                  <div className="signature-preview">
                    <img
                      src={
                        signatureImages[
                          SignPageIndex === 1
                            ? "CUSTOMER_SIGNATURE"
                            : "COCUSTOMER_SIGNATURE"
                        ]
                      }
                      alt="Wet Signature"
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </div>
                )}
              </div>

              <div className="Checkbox_input">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={InputChecked}
                  onChange={InputCheckboxChange}
                  onClick={playClickSound}
                />
                <span> {translations[language].IAgreeWithAllPurchases}</span>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                className="Cancel_Btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeEsignatureModal();
                }}
              >
                Fetch PDFs
              </button>
              <button
                className="Cancel_Btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeEsignatureModal();
                }}
              >
                {translations[language].Cancel}
              </button>
              <button className="WetSign_Btn" onClick={handleWetSignClick}>
                {translations[language].UploadSign}
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept="image/png, image/jpeg, image/jpg"
                onChange={handleFileChange}
              />
              {canSignIndex === 1 || SignPageIndex === 2 ? (
                <MyButton
                  className="MyBtn"
                  title={translations[language].AcceptESign}
                  data-bs-toggle="modal"
                  data-bs-target="#GREATJOB"
                  disabled={!InputChecked || !isSignaturePresent}
                  onClick={() => {
                    handleAcceptSign();
                    handleContractSubmit();
                    setSignPageIndex(1);
                    InputCheckboxChange();
                  }}
                />
              ) : (
                <MyButton
                  className="MyBtn"
                  title={translations[language].Next}
                  disabled={!InputChecked || !isSignaturePresent}
                  onClick={() => {
                    NextSignHandle();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/*========== here is start modal ServicePDFs ========== */}

      <div
        className="modal fade"
        id="ServicePDFs"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog ServicePDFs">
          <div className="modal-content border-0">
            <div className="modal-header">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              ></button>
              <div className="d-flex align-items-center gap-2">
                <BsFileEarmarkPdfFill style={{ fontSize: "30px" }} />
                <h1 className="modal-title">
                  {translations[language].ContractPDFs}
                </h1>
              </div>
            </div>
            <div className="modal-body text-center">
              {Pdfloading ? (
                <BookLoader />
              ) : (
                <ul
                  style={{
                    listStyle: "auto",
                    textAlign: "left",
                    lineHeight: 2,
                    fontSize: "15px",
                  }}
                >
                  {contractPdfs.map((pdf, index) =>
                    pdf.Product ? (
                      <li key={index} style={{ marginBottom: "15px" }}>
                        <span style={{ display: "block", width: "75%" }}>
                          {pdf.Product.ProductName} - {pdf.ContractNumber}
                        </span>

                        <button
                          className="border-0 bg-transparent"
                          style={{
                            position: "absolute",
                            right: "12px",
                            marginTop: "-40px",
                            fontSize: "24px",
                          }}
                          onClick={() => {
                            handlePdfDownload(
                              pdf.ContractNumber,
                              pdf.Product.ProductCode,
                              index
                            );
                          }}
                        >
                          {downloadingIndex === index ? (
                            <MdOutlineDownloading />
                          ) : (
                            <MdOutlineDownloadForOffline />
                          )}
                        </button>
                      </li>
                    ) : (
                      <li key={index} style={{ marginBottom: "15px" }}>
                        <span style={{ display: "block", width: "75%" }}>
                          {pdf?.productName} -
                          {
                            pdf?.EX1ContractResponse?.MsgHeader?.Message
                              ?.Description
                          }
                        </span>

                        <button
                          className="btn btn-sm btn-warning"
                          style={{
                            position: "absolute",
                            right: "12px",
                            marginTop: "-30px",
                          }}
                        >
                          {downloadingIndex === index ? "Invalid" : "Invalid"}
                        </button>
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
            <div className="modal-footer justify-content-center">
              <button
                data-bs-toggle="modal"
                data-bs-target="#Share_Policies"
                className="MyBtn"
                disabled={shareDisabled}
              >
                {shareDisabled ? `Uploading...(${uploadedCount})` : "Share"}
              </button>

              <button
                className="MyBtn"
                onClick={() => {
                  handleDownloadZip();
                }}
              >
                {DownloadZip
                  ? translations[language].Downloading
                  : translations[language].DownloadZipFile}
              </button>
              <MyButton
                className="MyBtn"
                title={translations[language].Done}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  clearSignature();
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start modal GREATJOB ========== */}

      <div
        className="modal fade"
        id="GREATJOB"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                playClickSound();
                clearSignature();
                setContractLoading(true);
              }}
            ></button>
            {!ContractLoading ? (
              <>
                <div className="modal-body p-0 text-center">
                  <div>
                    <img
                      className=""
                      src="/assets/Images/dislikeImage.svg"
                      alt="GREATJOB"
                    />
                    <h4>{translations[language].GreatJob}!</h4>
                    <p> {translations[language].WeEncourageYouToShareText}</p>
                  </div>
                </div>
                <div className="modal-footer border-0 justify-content-center p-0">
                  <button
                    className="bgColor_Btn"
                    data-bs-toggle="modal"
                    data-bs-target="#ServicePDFs"
                    onClick={() => {
                      setContractLoading(true);
                      setShareDisabled(true);
                      handleUploadMergedPdfs();
                    }}
                  >
                    {translations[language].View}
                  </button>
                </div>
              </>
            ) : ContractLoadingError ? (
              <div className="modal-body text-center">
                <img
                  className=""
                  src="/assets/Images/DashboardImages/triangle-warning.svg"
                  alt="warning img"
                />
                <h4>{translations[language].Oops}!</h4>
                <p>{translations[language].WeCouldntFetchPDFs}</p>
                <button
                  className="bgColor_Btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setContractLoading(true)}
                >
                  {translations[language].Close}
                </button>
              </div>
            ) : (
              <BookLoader />
            )}
          </div>
          {!ContractLoading && (
            <div className="GREATJOB_Message">
              <p>{translations[language].GREATJOB_Message}</p>
            </div>
          )}
        </div>
      </div>

      {/*========== here is start modal Share Policies ========== */}

      <div
        className="modal fade"
        id="Share_Policies"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals p-4">
          <div className="modal-content p-0 border-0 Edit_Product">
            <div className="modal-header justify-content-start gap-2 p-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <FaShareAlt style={{ fontSize: "32px" }} />
              <h4>{translations[language].SharePolicies}</h4>
            </div>
            <div className="modal-body p-4">
              <div className="gap-2 d-flex flex-column">
                <InputFiled
                  onChange={handleEmailChange}
                  value={email}
                  lableValue={translations[language].EmailAddress}
                  InputType="emial"
                  Inputplaceholder="cynthiaj@gmail.com"
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </div>
            </div>
            <div className="modal-footer justify-content-center p-4">
              <button
                className="w-100 MyBtn"
                onClick={handleSharePolicy}
                value={email}
                data-bs-toggle={!SharePolicieDisable ? "modal" : undefined}
                data-bs-target={
                  !SharePolicieDisable ? "#Policy_Shared" : undefined
                }
              >
                {translations[language].ShareNow}
              </button>
            </div>
          </div>
          <div className="GREATJOB_Message">
            <p>{translations[language].YouHaveUnlockedReward}</p>
          </div>
        </div>
      </div>

      {/*========== here is start modal Policy Shared ========== */}

      <div
        className="modal fade"
        id="Policy_Shared"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                playClickSound();
                clearSignature();
              }}
            ></button>
            <div className="modal-body p-0 text-center ">
              <img
                className=""
                src="/assets/Images/dislikeImage.svg"
                alt="GREATJOB"
              />
              <h4>{translations[language].PolicyShared}</h4>
              <p>{translations[language].YouCanDownloadThePolicy}</p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <button
                className="MyBtn"
                data-bs-toggle="modal"
                data-bs-target="#Reviewsmodal"
                onClick={() => {
                  playClickSound();
                  clearSignature();
                }}
              >
                {translations[language].ShareReview}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardLeftSidebar;
