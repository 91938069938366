import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useLanguage } from "../../LanguageContext";

import axios from "axios";

import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import translations from "../../translations.json";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";

const EditDeal = ({ setIsLogin }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const { language, toggleLanguage } = useLanguage();
  const [isPlaying, setIsPlaying] = useState(true);
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  // const [filteredCities, setFilteredCities] = useState([]);

  const { playPreloadedVideo, isVideoEndedEditDeal } = useVideo();
  const { id } = useParams();
  const { playClickSound } = useAudio();
  const navigate = useNavigate();

  const hasMounted = useRef(false);
  const videoRef = useRef(null);
  const usertoken = localStorage.getItem("token");
  let half = "mb-3 DisabledInputs";

  const [dealData, setDealData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicleColor: "",
    free_add_on: "",
    taxes: "",
    deal_number: "",
    loan_term: "",
    purchase_price: "",
    interest_rate: "",
    down_payment: "",
    vehicle_payment: "",
    net_trade: "",
    protection_items: "",
    rebate: "",
    total_monthly_payment: "",
    vinNumber: "",
    contract_date: "",
    inService_date: "",
  });

  useEffect(() => {
    if (hasMounted.current && isFormSubmitted) {
      validateForm();
    } else {
      hasMounted.current = true;
    }
  }, [toggleLanguage]);

  const validateForm = () => {
    setIsFormSubmitted(true);
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      if (dealData[field] !== undefined) {
        let value = dealData[field];

        // If it's a phone number, clean it first
        if (field === "phoneNumber") {
          value = value.replace(/\D/g, ""); // Remove all non-digit characters
        }
        const messages = rules.messages[language]; // Fetch language-specific messages
        if (field === "middleName" && !value) continue;
        if (field === "vinNumber" && !value) continue;
        // Only validate if the field is required or has a value
        if (rules.required && !value) {
          newErrors[field] = messages?.required || "Validation error.";
        } else if (value) {
          if (rules.minLength && value.length <= rules.minLength) {
            newErrors[field] = messages?.minLength || "Validation error.";
          } else if (rules.maxLength && value.length > rules.maxLength) {
            newErrors[field] = messages?.maxLength || "Validation error.";
          } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
            newErrors[field] = messages?.pattern || "Validation error.";
          }
        }
      }
    }

    // Check if select fields are selected
    if (!dealData.city)
      newErrors.city =
        language === "es"
          ? "Por favor seleccione una ciudad."
          : "Please select a city.";
    if (!dealData.state)
      newErrors.state =
        language === "es"
          ? "Por favor seleccione un estado."
          : "Please select a state.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/show/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        }
      );
      if (!response.ok) {
        localStorage.clear();
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setDealData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleLoadedData = (videoSrc, ref) => {
    playPreloadedVideo(videoSrc, ref);
  };

  // Filter cities whenever the state in dealData changes
  // useEffect(() => {
  //   if (dealData.state) {
  //     const matchingCities = cityData.filter((city) => city.state_id === Number(dealData.state));
  //     if (matchingCities.length > 0) {
  //       setFilteredCities(matchingCities);
  //     } else {
  //       setFilteredCities([
  //         {
  //           id: 1,
  //           name: "",
  //         },
  //       ]);
  //       // console.log("No matching cities found");
  //     }
  //   }
  // }, [dealData.state, cityData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Only allow numbers in InputType="tel"
    // const numericValue = value.replace(/\D/g, "");
    let numericValue = value.replace(/\D/g, "");
    if (name === "phoneNumber") {
      // Format phone number as the user types
      if (numericValue.length > 3 && numericValue.length <= 6) {
        numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
      } else if (numericValue.length > 6) {
        numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6, 10)}`;
      }

      setDealData({
        ...dealData,
        [name]: numericValue,
      });
    } else if (name === "state") {
      const stateValue = Number(numericValue); // Convert state value to number
      setDealData({
        ...dealData,
        [name]: stateValue,
      });
    } else {
      setDealData({
        ...dealData,
        [name]: value,
      });
    }
  };

  const handleSave = async () => {
    // Remove formatting from phone number before sending it to the API
    const rawPhoneNumber = dealData.phoneNumber.replace(/\D/g, ""); // Remove everything except digits

    const dealDataToSend = {
      ...dealData,
      phoneNumber: rawPhoneNumber, // Send the raw phone number
    };

    if (!validateForm()) {
      toast.dismiss();
      toast.warn(translations[language]?.FailedToAddDeal);
      return;
    }

    // If validation passes, proceed with the API call
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/update/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
          body: JSON.stringify(dealDataToSend),
        }
      );

      if (!response.ok) {
        localStorage.clear();
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      toast.dismiss();
      toast.success(translations[language]?.DealUpdatedSuccessfully);
    } catch (error) {
      console.error("Error updating data:", error);
    }
    navigate(`/DetailVerification/${id}`);
  };

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play();
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play();
      setIsPlaying(true);
    }
  };

  const togglePlay = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (isVideoEndedEditDeal) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEndedEditDeal]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/states/show",
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        );
        setstateData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get(
  //         "https://api.menuverse.ai/cities/showbystate",
  //         { state: dealData.state },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${usertoken}`,
  //           },
  //         }
  //       );
  //       setcityData(response.data.data);
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   };
  //   fetchData();
  // }, [dealData.state]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if a valid state is selected before calling the API
        if (dealData.state) {
          const response = await axios.post(
            "https://api.menuverse.ai/cities/showbystate",
            { state: dealData.state }, // Pass the state ID here
            {
              headers: {
                Authorization: `Bearer ${usertoken}`,
              },
            }
          );
          setcityData(response.data.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [dealData.state]);

  // Use that function to format the phone number before passing it to the InputFiled component.

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return raw if it doesn't match the expected length
  };

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center ">
            <div className="col-lg-9">
              <div className="Full_Table">
                <TableHeader
                  title={translations[language].ABCDealerGroup}
                  Monthlyfinance={true}
                />

                <div className="container Add_Dealer Edit-Deal-container">
                  <div className="row row-cols-lg-2 row-cols-md-1">
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="firstName"
                        lableValue={translations[language].FirstName}
                        required
                        value={dealData.firstName}
                        onChange={handleChange}
                        errorMessage={errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="Edit-deal-error-text">
                          {errors.firstName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="middleName"
                        lableValue={translations[language].MiddleName}
                        value={dealData.middleName}
                        onChange={handleChange}
                        errorMessage={errors.middleName}
                      />
                      {errors.middleName && (
                        <p className="Edit-deal-error-text">
                          {errors.middleName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="lastName"
                        lableValue={translations[language].LastName}
                        required
                        value={dealData.lastName}
                        onChange={handleChange}
                        errorMessage={errors.lastName}
                      />
                      {errors.lastName && (
                        <p className="Edit-deal-error-text">
                          {errors.lastName}
                        </p>
                      )}
                    </div>

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputName="phoneNumber"
                        lableValue={translations[language].PhoneNumber}
                        InputID={"phoneNumber"}
                        required
                        value={formatPhoneNumber(dealData.phoneNumber)}
                        onChange={handleChange}
                        maxlength="15"
                        errorMessage={errors.phoneNumber}
                      />
                      {errors.phoneNumber && (
                        <p className="Edit-deal-error-text">
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="email"
                        InputName="email"
                        lableValue={translations[language].Email}
                        required
                        value={dealData.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <p className="Edit-deal-error-text">{errors.email}</p>
                      )}
                    </div>

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="streetAddress"
                      lableValue={translations[language].StreetAddress}
                      required
                      value={dealData.streetAddress}
                      onChange={handleChange}
                    />

                    {/* State */}
                    <SelectField
                      className={`${half} Select-required`}
                      InputName="state"
                      Selectlabel={translations[language].State}
                      value={dealData.state}
                      onChange={handleChange}
                      options={stateData.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />

                    {/* City */}
                    <SelectField
                      className={`${half} Select-required`}
                      InputName="city"
                      Selectlabel={translations[language].City}
                      value={dealData.city}
                      onChange={handleChange}
                      options={cityData.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />

                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputID={"zipCode"}
                        InputName="zipCode"
                        lableValue={translations[language].ZipCode}
                        required
                        minlength="5"
                        maxlength="12"
                        value={dealData.zipCode}
                        onChange={handleChange}
                        errorMessage={errors.zipCode}
                      />
                      {errors.zipCode && (
                        <p className="Edit-deal-error-text">{errors.zipCode}</p>
                      )}
                    </div>

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="vehicleColor"
                      lableValue={translations[language].VehicleColor}
                      disabled
                      value={dealData.vehicleColor}
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="VINnumber"
                      lableValue={translations[language].VinNumber}
                      disabled
                      value={dealData.vinNumber}
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="FeeAddons"
                      lableValue={translations[language].FeeAddOns}
                      disabled
                      value={
                        dealData.free_add_on
                          ? `$    |    ${dealData.free_add_on}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="Vehicle"
                      lableValue={translations[language].Vehicle}
                      disabled
                      value={
                        dealData.vehicle ? `$    |    ${dealData.vehicle}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Taxes"
                      lableValue={translations[language].Taxes}
                      disabled
                      value={
                        dealData.taxes ? `$    |    ${dealData.taxes}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Dealnumber"
                      lableValue={translations[language].DealNumber}
                      disabled
                      value={
                        dealData.deal_number
                          ? `$    |    ${dealData.deal_number}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="text"
                      InputName="Loanterm"
                      lableValue={translations[language].LoanTerm}
                      disabled
                      value={
                        dealData.deal_number
                          ? `$    |    ${dealData.deal_number}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Purchaseprice"
                      lableValue={translations[language].Purchaseprice}
                      disabled
                      value={
                        dealData.purchase_price
                          ? `$    |    ${dealData.purchase_price}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Interestrate"
                      lableValue={translations[language].InterestRate}
                      disabled
                      value={
                        dealData.interest_rate
                          ? `$    |    ${dealData.interest_rate}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Downpayment"
                      lableValue={translations[language].DownPayment}
                      disabled
                      value={
                        dealData.down_payment
                          ? `$    |    ${dealData.down_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Vehiclepayment"
                      lableValue={translations[language].Vehiclepayment}
                      disabled
                      value={
                        dealData.vehicle_payment
                          ? `$    |    ${dealData.vehicle_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Nettrade"
                      lableValue={translations[language].NetTrade}
                      disabled
                      value={
                        dealData.net_trade
                          ? `$    |    ${dealData.net_trade}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Protectionitems"
                      lableValue={translations[language].ProtectionItems}
                      disabled
                      value={
                        dealData.protection_items
                          ? `$    |    ${dealData.protection_items}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Rebate"
                      lableValue={translations[language].Rebate}
                      disabled
                      value={
                        dealData.rebate ? `$    |    ${dealData.rebate}` : ""
                      }
                      onChange={handleChange}
                    />

                    <InputFiled
                      InputBoxClassname={half}
                      InputType="tel"
                      InputName="Totalmonthlypayment"
                      lableValue={translations[language].TotalMonthlyPayment}
                      disabled
                      value={
                        dealData.total_monthly_payment
                          ? `$    |    ${dealData.total_monthly_payment}`
                          : ""
                      }
                      onChange={handleChange}
                    />

                    {/* Contract Date */}

                    {/* <InputFiled
                      disabled
                      InputBoxClassname={half}
                      InputType="date"
                      InputName="contract_date"
                      lableValue={translations[language].ContractDate}
                      value={dealData.contract_date}
                      onChange={handleChange}
                    /> */}

                    {/* In Service Date */}
                    {/* <InputFiled
                      disabled
                      InputBoxClassname={half}
                      InputType="date"
                      InputName="inService_date"
                      lableValue={translations[language].InServiceDate}
                      value={dealData.inService_date}
                      onChange={handleChange}
                    /> */}
                  </div>
                </div>

                <div className="Table_foot">
                  <div className="Foot_btn pe-5 me-5">
                    <NavLink to={`/DetailVerification/${id}`}>
                      <MyButton
                        title={translations[language].Cancel}
                        className="Secondry_Btn"
                      />
                    </NavLink>
                    <MyButton
                      title={translations[language].SaveChange}
                      data-bs-toggle="modal"
                      data-bs-target="#DealConfirmation"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal />

        {/*========== here is start shared Successfully!  ========== */}

        <div
          className="modal fade"
          id="DealConfirmation"
          data-bs-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog Waiting_pop Changed_Successfully">
            <div className="modal-content border-0 ">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <div className="modal-body p-0 text-center ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="60"
                  viewBox="0 0 45 46"
                  fill="none"
                >
                  <g clipPath="url(#clip0_347_3959)">
                    <path
                      d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z"
                      fill="#E0C22D"
                    ></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_347_3959">
                      <rect
                        width="45"
                        height="45"
                        fill="white"
                        transform="translate(0 0.758301)"
                      ></rect>
                    </clipPath>
                  </defs>
                </svg>
                <h4>{translations[language].AreYouSureToChangeDetails}</h4>
              </div>
              <div className="modal-footer border-0 justify-content-center p-0">
                <MyButton
                  title="NO"
                  className="Secondry_Btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />

                <button
                  className="MyBtn"
                  onClick={() => {
                    handleSave();
                    playClickSound();
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {translations[language].Yes}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="DetailVerification_Versa">
          <div className="DetailVerification_Versa_Bottons">
            {isPlaying ? (
              <button onClick={pauseVideo}>
                <FaPause />
              </button>
            ) : (
              <button onClick={playVideo}>
                <FaPlay />
              </button>
            )}
            <button onClick={replayVideo}>
              <MdOutlineReplay />
            </button>
          </div>
          <div className="Bot_Buttons Big-size-background-Ai-video">
            <video ref={videoRef} onClick={togglePlay} autoPlay unmuted></video>
            <img
              onClick={togglePlay}
              className="Big-size-background-Ai"
              src="/assets/Images/DashboardImages/newversaNew.jpg"
              alt=""
              onLoad={() => {
                handleLoadedData("dealEditVideoSrc", videoRef);
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default EditDeal;
