import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useLanguage } from "../../LanguageContext";

import MyButton from "../CommonFolder/MyButton";
import translations from "../../translations.json";

const Modal = () => {
  const [InputChecked, setInputChecked] = useState(false);
  const [isPlaying, setisPlaying] = useState(true);
  const [
    isPlayingpauseLetTakeMomentVideo,
    setisPlayingpauseLetTakeMomentVideo,
  ] = useState(true);
  const [showErrorForCheck, setshowErrorForCheck] = useState(false);

  const { playPreloadedVideo, isVideoEnded, isVideoEndedForMoment } =
    useVideo();
  const { playClickSound } = useAudio();
  const { language } = useLanguage();
  const { id } = useParams();
  const videoRef = useRef(null);
  const navigate = useNavigate();

  const LetTakeMomentvideoRef = useRef(null);
  const usertoken = localStorage.getItem("token");

  const playAudio = () => {
    playClickSound();

    if (InputChecked) {
      try {
        const deal_data = {
          deal_id: id,
        };
        const response = fetch("https://api.menuverse.ai/fni_products/create", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
          body: JSON.stringify(deal_data),
        });

        // if (response.status === 201 || response.status === 200) {
        // } else if (response.status === 500) {
        //   const result = await response.json();
        //   window.toastr.error(result.message);
        // } else if (response.status === 401 || response.status === 403) {
        //   navigate("/login");
        // }

        // const responseData = await response.json();
        // console.log(responseData);
      } catch (error) {
        // console.log("Error Occured", error);
      }
    }
  };

  const handleCheckboxChange = () => {
    setInputChecked(!InputChecked);
  };

  const handleNavigate = () => {
    playAudio();
    navigate(`/Questionnaire/${id}`);
    setshowErrorForCheck(false);
  };

  useEffect(() => {
    const handleShown = () => {
      playPreloadedVideo("video1", videoRef);
    };

    const handleShown1 = () => {
      playPreloadedVideo("LetsTakeMomentVideoSrc", LetTakeMomentvideoRef);
    };

    const modalElementLetTakeMoment = document.getElementById("LetTakeMoment");
    modalElementLetTakeMoment.addEventListener("shown.bs.modal", handleShown1);

    const modalElement = document.getElementById("AddDealModal");
    modalElement.addEventListener("shown.bs.modal", handleShown);

    return () => {
      modalElement.removeEventListener("shown.bs.modal", handleShown);
    };
  }, []);

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setisPlaying(true);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setisPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setisPlaying(false);
    }
  };

  useEffect(() => {
    if (isVideoEnded) {
      setisPlaying(false);
    } else {
      setisPlaying(true);
    }
  }, [isVideoEnded]);

  useEffect(() => {
    if (isVideoEndedForMoment) {
      setisPlayingpauseLetTakeMomentVideo(false);
    } else {
      setisPlayingpauseLetTakeMomentVideo(true);
    }
  }, [isVideoEndedForMoment]);

  // LetTakeMomentvideoRef video code start here to pla pause and replay the video

  const playLetTakeMomentVideo = () => {
    const letTakeVide = LetTakeMomentvideoRef.current;
    if (letTakeVide) {
      letTakeVide.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setisPlayingpauseLetTakeMomentVideo(true);
    }
  };

  const replayLetTakeMomentVideo = () => {
    const letTakeVide = LetTakeMomentvideoRef.current;
    if (letTakeVide) {
      letTakeVide.currentTime = 0;
      letTakeVide.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setisPlayingpauseLetTakeMomentVideo(true);
    }
  };

  const pauseLetTakeMomentVideo = () => {
    const letTakeVide = LetTakeMomentvideoRef.current;
    if (letTakeVide) {
      letTakeVide.pause();
      setisPlayingpauseLetTakeMomentVideo(false);
    }
  };

  return (
    <>
      {/*========== here is start AddModal ========== */}

      <div
        className="modal fade"
        id="AddDealModal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop sfs">
          <div className="modal-content border-0 bg-transparent">
            <div className="DetailVerification_Versa_Bottons">
              {isPlaying ? (
                <button onClick={pauseVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={playVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={replayVideo}>
                <MdOutlineReplay />
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="background-video position-static">
                <div>
                  <video
                    className="w-100 Double_Videos"
                    id="myVideo"
                    ref={videoRef}
                  ></video>
                  <img
                    className="DetailVerification-Versa-ImgFor-videoBg"
                    src="/assets/Images/DashboardImages/newversaNew.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center pb-0">
              <NavLink to="/AddDeal">
                <MyButton
                  title={translations[language].Continue}
                  className="Secondry_Btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Disclaimer ========== */}

      <div
        className="modal fade"
        id="DisclaimerModal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop modal-dialog-scrollable modal-lg">
          <div className="modal-content border-0">
            <div className="modal-header p-4">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <div className="d-flex align-items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 45 46"
                  fill="none"
                >
                  <g clipPath="url(#clip0_347_3959)">
                    <path
                      d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z"
                      fill="#2E302E"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_347_3959">
                      <rect
                        width="45"
                        height="45"
                        fill="white"
                        transform="translate(0 0.758301)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <h1 className="modal-title" id="exampleModalLabel">
                  {translations[language].Disclaimer}
                </h1>
              </div>
            </div>
            <div className="modal-body px-4 List_Modal me-3 mb-4 pb-0">
              <div>
                <b>{translations[language].ImportantInfo}</b>
                <br />
                <br />
                <ol>
                  <li>
                    Accuracy of Information:
                    <ul>
                      <li>
                        We strive to provide accurate and up-to-date information
                        on our menus. However, menu items, prices, and
                        availability may change from time to time without prior
                        notice.
                      </li>
                      <li>
                        We recommend checking with the restaurant directly for
                        the latest information before placing an order.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Food Allergies and Dietary Restrictions:
                    <ul>
                      <li>
                        It is the customer's responsibility to inform the
                        restaurant of any food allergies or dietary restrictions
                        before placing an order.
                      </li>
                      <li>
                        We cannot guarantee that any menu items are completely
                        free of allergens, as kitchens may handle various
                        ingredients.
                      </li>
                      <li>
                        If you have concerns, please contact the restaurant
                        directly to discuss your specific needs.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Delivery Disclaimer:
                    <ul>
                      <li>
                        Delivery times are estimates and may vary depending on
                        factors such as traffic, weather, and restaurant order
                        volume.
                      </li>
                      <li>
                        We recommend placing your order well in advance,
                        especially during peak hours.
                      </li>
                      <li>
                        Delivery fees may apply and can vary depending on the
                        distance and restaurant.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Payment:
                    <ul>
                      <li>
                        We accept various payment methods, but these may vary
                        depending on the restaurant and delivery platform.
                      </li>
                      <li>
                        Please ensure you have a valid payment method available
                        before placing an order.
                      </li>
                      <li>
                        Additional fees, such as taxes and gratuities, may apply
                        to your order.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>

              <div className="modal-footer justify-content-between d-lg-flex mt-3 d-md-block">
                <div className="d-flex gap-2 align-items-center">
                  <input
                    className="form-check-input m-0"
                    type="checkbox"
                    checked={InputChecked}
                    onChange={handleCheckboxChange}
                    onClick={playClickSound}
                  />
                  <div className="position-relative">
                    <span> {translations[language].AgreeTermsConditions}</span>
                    {showErrorForCheck && (
                      <p className="Disclaimer-error-message">
                        {translations[language].YouMustAcceptTermProceed}
                      </p>
                    )}
                  </div>
                </div>
                {InputChecked ? (
                  <button
                    className="MyBtn"
                    data-bs-toggle="modal"
                    data-bs-target="#LetTakeMoment"
                  >
                    {translations[language].Continue}
                  </button>
                ) : (
                  <button
                    className="MyBtn"
                    onClick={() => {
                      playClickSound();
                      setshowErrorForCheck(true);
                    }}
                  >
                    {translations[language].Continue}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Let's take a moment ========== */}

      <div
        className="modal fade"
        id="LetTakeMoment"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog Waiting_pop ">
          <div className="modal-content border-0 bg-transparent">
            <div className="DetailVerification_Versa_Bottons">
              {isPlayingpauseLetTakeMomentVideo ? (
                <button onClick={pauseLetTakeMomentVideo}>
                  <FaPause />
                </button>
              ) : (
                <button onClick={playLetTakeMomentVideo}>
                  <FaPlay />
                </button>
              )}
              <button onClick={replayLetTakeMomentVideo}>
                <MdOutlineReplay />
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="background-video position-static">
                <div>
                  <video
                    className="w-100 Double_Videos"
                    ref={LetTakeMomentvideoRef}
                  ></video>
                  <img
                    className="DetailVerification-Versa-ImgFor-videoBg"
                    src="/assets/Images/DashboardImages/newversaNew.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center pb-0">
              <MyButton
                title={translations[language].Continue}
                className="Secondry_Btn"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleNavigate();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
