import React, { useState, useEffect, useRef } from "react";
import { useAudio } from "../../../AudioContext";
import { toast } from "react-toastify";
import { useLanguage } from "../../../LanguageContext";

import axios from "axios";
import translations from "../../../translations.json";
import MyButton from "../../CommonFolder/MyButton";
import SelectField from "../../CommonFolder/FormStructure/SelectField";

const DashboardModals = ({
  productData,
  modalId,
  couponModalId,
  productDetail,
  interestData,
  termData,
  second_termData,
  onSaveMenu,
}) => {
  const [productUpdatedData, setproductUpdatedData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [productTermsData, setProductTermsData] = useState([]);
  const [productMilesData, setProductMilesData] = useState([]);
  const [productDeductiblesData, setProductDeductiblesData] = useState([]);
  const [serviceIntervalData, setServiceIntervalData] = useState([]);
  const [tireRotationData, setTireRotationData] = useState([]);
  const [productPrice, setProductPrice] = useState(0);
  const [IsproductPriceLoading, setIsproductPriceLoading] = useState(false);

  const videoRef = useRef(null);
  const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
  const usertoken = localStorage.getItem("token");
  const divRef = useRef(null);
  const { playClickSound } = useAudio();
  const { language } = useLanguage();

  const initialReviews = [
    { category: "Ease of use", rating: 4 },
    { category: "Performance", rating: 5 },
    { category: "Functionality", rating: 2 },
  ];
  const [reviews, setReviews] = useState(initialReviews);

  const menuIdRef = useRef(null);
  const menuIdDiscountRef = useRef(null);
  const couponIdDiscountRef = useRef(null);
  const productRef = useRef(null);
  const mileRef = useRef(null);
  const termRef = useRef(null);
  const deductibleRef = useRef(null);
  const tireRotationRef = useRef(null);
  const serviceIntervalRef = useRef(null);
  const handleProductChange = async (e) => {
    const productId = e.target.value;
    getTerms(productId);
  };

  const getTerms = async (productId) => {
    const response = await fetch(
      "https://api.menuverse.ai/menu/getProductTerms/" + productId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (response.status == 200) {
      const responseJson = await response.json();

      const products = responseJson.data;

      const productTermOptions = products.map((productTerm) => {
        return {
          value: productTerm.months,
          label: productTerm.months + " Months",
        };
      });

      productTermOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductTermsData(productTermOptions);
      // console.log(productOptions);
      getPrice();

      // mileRef.current.value = "Select";
      // termRef.current.value = "Select";
      // deductibleRef.current.value = "Select";
    }
  };

  const getMiles = async () => {
    const milesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductMiles/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (milesResponse.status == 200) {
      const milesResponseJson = await milesResponse.json();

      const productsMiles = milesResponseJson.data;

      const productMileOptions = productsMiles.map((productMile) => {
        return {
          value: productMile.miles,
          label: productMile.miles,
        };
      });

      productMileOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductMilesData(productMileOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getMilesWithParams = async (id, term) => {
    const milesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductMiles/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (milesResponse.status == 200) {
      const milesResponseJson = await milesResponse.json();

      const productsMiles = milesResponseJson.data;

      const productMileOptions = productsMiles.map((productMile) => {
        return {
          value: productMile.miles,
          label: productMile.miles,
        };
      });

      productMileOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductMilesData(productMileOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getServiceIntervals = async () => {
    const serviceResponse = await fetch(
      "https://api.menuverse.ai/menu/getServiceIntervals/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (serviceResponse.status == 200) {
      const serviceResponseJson = await serviceResponse.json();

      const servicIntervals = serviceResponseJson.data;

      const servicIntervalOptions = servicIntervals.map((servicInterval) => {
        return {
          value: servicInterval.service_interval,
          label: servicInterval.service_interval,
        };
      });

      servicIntervalOptions.unshift({
        value: "",
        label: "Select",
      });

      setServiceIntervalData(servicIntervalOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getServiceIntervalsWithParams = async (id, term) => {
    const serviceResponse = await fetch(
      "https://api.menuverse.ai/menu/getServiceIntervals/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (serviceResponse.status == 200) {
      const serviceResponseJson = await serviceResponse.json();

      const servicIntervals = serviceResponseJson.data;

      const servicIntervalOptions = servicIntervals.map((servicInterval) => {
        return {
          value: servicInterval.service_interval,
          label: servicInterval.service_interval,
        };
      });

      servicIntervalOptions.unshift({
        value: "",
        label: "Select",
      });

      setServiceIntervalData(servicIntervalOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  const getTireRotations = async () => {
    const tireRotationResponse = await fetch(
      "https://api.menuverse.ai/menu/getTireRotations/" +
        productRef.current.value +
        "/" +
        termRef.current.value +
        "/" +
        serviceIntervalRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (tireRotationResponse.status == 200) {
      const tireRotationJson = await tireRotationResponse.json();

      const tireRotations = tireRotationJson.data;

      const tireRoattionOptions = tireRotations.map((tireRotation) => {
        return {
          value: tireRotation.tire_rotations,
          label: tireRotation.tire_rotations,
        };
      });

      tireRoattionOptions.unshift({
        value: "",
        label: "Select",
      });

      setTireRotationData(tireRoattionOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getTireRotationsWithParams = async (id, months, intervals) => {
    const tireRotationResponse = await fetch(
      "https://api.menuverse.ai/menu/getTireRotations/" +
        id +
        "/" +
        months +
        "/" +
        intervals,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (tireRotationResponse.status == 200) {
      const tireRotationJson = await tireRotationResponse.json();

      const tireRotations = tireRotationJson.data;

      const tireRoattionOptions = tireRotations.map((tireRotation) => {
        return {
          value: tireRotation.tire_rotations,
          label: tireRotation.tire_rotations,
        };
      });

      tireRoattionOptions.unshift({
        value: "",
        label: "Select",
      });

      setTireRotationData(tireRoattionOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };

  const getDeductible = async () => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        productRef.current.value +
        "/" +
        termRef.current.value +
        "/" +
        mileRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getDeductibleWithParams = async (id, term, miles) => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        id +
        "/" +
        term +
        "/" +
        miles,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  const getDeductibleTwo = async () => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" +
        productRef.current.value +
        "/" +
        termRef.current.value,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      getPrice();
    }
  };

  const getDeductibleWithTwoParams = async (id, term) => {
    const deductiblesResponse = await fetch(
      "https://api.menuverse.ai/menu/getProductDeductibles/" + id + "/" + term,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
      }
    );

    if (deductiblesResponse.status == 200) {
      const deductiblesResponseJson = await deductiblesResponse.json();

      const deductiblesProducts = deductiblesResponseJson.data;

      const productDeductibleOptions = deductiblesProducts.map(
        (productdeductible) => {
          return {
            value: productdeductible.deductible,
            label: "$" + productdeductible.deductible,
          };
        }
      );

      productDeductibleOptions.unshift({
        value: "",
        label: "Select",
      });

      setProductDeductiblesData(productDeductibleOptions);
      // console.log(productOptions);
      // getPrice();
    }
  };
  useEffect(() => {
    if (productDetail?.id) {
      getTerms(productDetail.id);
      getMilesWithParams(productDetail.id, productDetail.months);
      getDeductibleWithParams(
        productDetail.id,
        productDetail.months,
        productDetail.miles
      );

      getServiceIntervalsWithParams(productDetail.id, productDetail.months);
      getTireRotationsWithParams(
        productDetail.id,
        productDetail.months,
        productDetail.service_interval
      );

      if (
        productDetail.category_type == "TWL" ||
        productDetail.category_type == "PDR"
      ) {
        getDeductibleWithTwoParams(productDetail.id, productDetail.months);
      }
    }
  }, [productData, modalId, productDetail]);

  const saveMenu = async () => {
    const data = {
      id: menuIdRef.current.value,
      product_id: productRef.current.value,
      miles: mileRef?.current?.value,
      months: termRef?.current?.value,
      deductible: deductibleRef?.current?.value,
      service_interval: serviceIntervalRef?.current?.value,
      tire_rotations: tireRotationRef?.current?.value,
      interest: interestData,
      term: termData,
      second_term: second_termData,
    };
    const menuResponse = await fetch("https://api.menuverse.ai/menu/saveMenu", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
      body: JSON.stringify(data),
    });

    if (menuResponse.status) {
      const menuResponseJson = await menuResponse.json();

      const menuData = menuResponseJson.data;
      onSaveMenu();
    }
  };

  const applyDiscount = async () => {
    const data = {
      id: menuIdDiscountRef.current.value,
      coupon_id: couponIdDiscountRef.current.value,
      interest: interestData,
      term: termData,
      second_term: second_termData,
    };
    const menuResponse = await fetch(
      "https://api.menuverse.ai/menu/applyDiscount",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (menuResponse.status) {
      const menuResponseJson = await menuResponse.json();

      const menuData = menuResponseJson.data;
      onSaveMenu();
    }
  };

  const handleProductSubmit = () => {
    // alert("clicked");
    saveMenu();
  };

  const handleCouponSubmit = () => {
    // alert("clicked");
    applyDiscount();
  };

  const handleOtherSelectChanges = () => {
    getPrice();
  };

  const handleTermChanges = async () => {
    getMiles();
    getServiceIntervals();
    getDeductibleTwo();
  };

  const handleServiceIntervalChange = () => {
    getTireRotations();
  };

  const handleMileChanges = async () => {
    getDeductible();
  };

  const getPrice = async () => {
    setIsproductPriceLoading(true);
    const data = {
      product_id: productRef?.current?.value,
      miles: mileRef?.current?.value,
      months: termRef?.current?.value,
      deductible: deductibleRef?.current?.value,
      service_interval: serviceIntervalRef?.current?.value,
      tire_rotations: tireRotationRef?.current?.value,
    };

    const priceResponse = await fetch(
      "https://api.menuverse.ai/menu/getPrice",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify(data),
      }
    );

    if (priceResponse.status == 200) {
      const responseJson = await priceResponse.json();
      const price = responseJson.data;

      setProductPrice(price.sell_price);
      setIsproductPriceLoading(false);
      // console.log("Price", price);
    } else {
      setProductPrice(0);
    }
  };

  const handleStarClick = (index, starIndex) => {
    const updatedReviews = reviews.map((review, reviewIndex) =>
      reviewIndex === index ? { ...review, rating: starIndex + 1 } : review
    );
    setReviews(updatedReviews);
  };

  const saveRatings = async () => {
    let easeOfUseRating = 0;
    let performanceRating = 0;
    let functionalityRating = 0;

    reviews.forEach((review) => {
      if (review.category === "Ease of use") {
        easeOfUseRating = review.rating;
      } else if (review.category === "Performance") {
        performanceRating = review.rating;
      } else if (review.category === "Functionality") {
        functionalityRating = review.rating;
      }
    });

    const payload = {
      use: easeOfUseRating.toString(),
      performance: performanceRating.toString(),
      functionality: functionalityRating.toString(),
      comment: reviewComment,
      dealId: Dealid,
    };

    try {
      const response = await axios.post(
        "https://api.menuverse.ai/review/create",
        payload,
        {
          headers: {
            Authorization: `Bearer ${usertoken}`, // Add the token here
            "Content-Type": "application/json", // Explicit content type
          },
        }
      );
      console.log("API Response:", response.data);
      toast.dismiss();
      toast.success("Review submitted successfully!");
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.dismiss();
      toast.warn("Failed to submit the review. Please try again.");
    }
  };

  return (
    <>
      {/*========== here is start NotAvailable ========== */}

      <div
        className="modal fade"
        id="NotAvailable"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals modal-fade-transform">
          <div className="modal-content border-0 ">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={playClickSound}
            ></button>
            <div className="modal-body p-0 text-center ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 45 46"
                fill="none"
              >
                <g clipPath="url(#clip0_347_3959)">
                  <path
                    d="M44.3399 33.9417L27.1066 4.93834C26.2449 3.47167 24.7233 2.59167 22.9999 2.59167C21.2766 2.59167 19.7549 3.47167 18.9116 4.93834L1.65992 33.9417C0.798255 35.445 0.798255 37.2233 1.65992 38.7083C2.52159 40.1933 4.06159 41.0917 5.76659 41.0917H40.2516C41.9749 41.0917 43.5149 40.1933 44.3583 38.7083C45.2016 37.2233 45.2016 35.445 44.3399 33.9417ZM24.8333 33.7583H21.1666V30.0917H24.8333V33.7583ZM24.8333 26.425H21.1666V15.425H24.8333V26.425Z"
                    fill="#E0C22D"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_347_3959">
                    <rect
                      width="45"
                      height="45"
                      fill="white"
                      transform="translate(0 0.758301)"
                    ></rect>
                  </clipPath>
                </defs>
              </svg>

              <h4>Not Available for Financing</h4>
              <p>
                This product is not available for financing , please ask your
                finance manager on how to purchase it.
              </p>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                title="ok"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start DiscountCoupons ========== */}

      <div
        className="modal fade"
        id={couponModalId}
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals DiscountCoupons">
          <div className="modal-content border-0 ">
            <div className="modal-header p-0 justify-content-center border-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <h4> {translations[language].DiscountCoupons}</h4>
            </div>

            <input
              type="hidden"
              ref={menuIdDiscountRef}
              value={productDetail?.menu_id}
            />
            <div className="modal-body p-0 text-center ">
              <p>{translations[language].OnlyOneCanApplied}</p>
              <ul className="list-group DiscountCouponsOptions">
                <li className="list-group-item">
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="DiscountCoupons"
                        className="form-check-input"
                        ref={couponIdDiscountRef}
                        value={productDetail?.coupon?.id}
                      />
                      <span> {productDetail?.coupon?.name}</span>
                    </label>
                  </div>
                  <div className="Discount_Saved">
                    <h6>$230 {translations[language].Saved}</h6>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                      disabled="true"
                    />
                    <span style={{ color: "#999" }}>Flat $250 off</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                      disabled="true"
                    />
                    <span style={{ color: "#999" }}>Buy 2 and get 1 Free</span>
                  </div>
                </li>
                <li className="list-group-item">
                  <div>
                    <input
                      type="radio"
                      name="DiscountCoupons"
                      className="form-check-input"
                      disabled="true"
                    />
                    <span style={{ color: "#999" }}>Other Coupon</span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                title="Apply Coupon"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCouponSubmit}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Reviewsmodal ========== */}

      <div
        className="modal fade"
        id="Reviewsmodal"
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals Reviewsmodal">
          <div className="modal-content p-4 border-0">
            <div className="modal-header p-0 justify-content-start">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <img
                src="../assets/Images/DashboardImages/BigBlackStar.svg"
                alt=""
              />
              <h4> {translations[language].Reviews}</h4>
            </div>
            <div className="modal-body p-0 text-center">
              {reviews.map((review, index) => (
                <div className="Reviews_Content_And_Stars" key={index}>
                  <span>{review.category}</span>
                  <div className="stars">
                    {[...Array(5)].map((_, starIndex) => (
                      <span
                        key={starIndex}
                        onClick={() => handleStarClick(index, starIndex)}
                      >
                        <img
                          className="clickTarget"
                          src={
                            starIndex < review.rating
                              ? "../assets/Images/DashboardImages/FullStar.svg"
                              : "../assets/Images/DashboardImages/Emptystar.svg"
                          }
                          alt=""
                          style={{ cursor: "pointer" }}
                          onClick={playClickSound}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              ))}
              <div className="Teaxtarea mt-3 mb-4">
                <textarea
                  value={reviewComment}
                  onChange={(e) => setReviewComment(e.target.value)}
                  placeholder={translations[language].WriteAboutYourExperience}
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-center p-0">
              <MyButton
                className="w-100 MyBtn"
                title={translations[language].Submit}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={saveRatings}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>

      {/*========== here is start Edit_Product ========== */}

      <div
        className="modal fade"
        id={modalId}
        data-bs-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog DashboardModals p-0">
          <div className="modal-content p-4 border-0 Edit_Product">
            <div className="modal-header px-0 justify-content-start gap-2">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={playClickSound}
              ></button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 20 20"
                fill="none"
                className="HiShare"
              >
                <g clipPath="url(#clip0_141_11738)">
                  <path
                    d="M16.1111 12.2225C15.4806 12.2227 14.8596 12.3762 14.3017 12.6699C13.7437 12.9636 13.2656 13.3887 12.9086 13.9083L7.49194 11.4625C7.8718 10.5297 7.87479 9.48583 7.50027 8.55085L12.9119 6.09335C13.4396 6.85896 14.2243 7.41007 15.1236 7.64668C16.0228 7.88329 16.9771 7.78974 17.8133 7.38298C18.6495 6.97623 19.3122 6.28323 19.6811 5.4297C20.0501 4.57617 20.1009 3.61866 19.8243 2.73088C19.5478 1.84309 18.9621 1.08385 18.1737 0.590892C17.3852 0.0979334 16.4462 -0.0960864 15.527 0.0440319C14.6077 0.18415 13.7692 0.649124 13.1634 1.3546C12.5576 2.06007 12.2248 2.95931 12.2253 3.88918C12.2253 4.11036 12.2439 4.33114 12.2811 4.54918L6.5386 7.15585C5.98501 6.63954 5.29278 6.29601 4.54679 6.16739C3.8008 6.03877 3.0335 6.13066 2.33897 6.43177C1.64444 6.73288 1.05289 7.23014 0.636853 7.86256C0.220816 8.49497 -0.00161605 9.23506 -0.00316568 9.99205C-0.0047153 10.749 0.214685 11.49 0.628129 12.1241C1.04157 12.7583 1.63109 13.2579 2.32438 13.5619C3.01766 13.8658 3.78459 13.9609 4.53109 13.8353C5.2776 13.7097 5.97124 13.369 6.52694 12.855L12.2769 15.4525C12.1418 16.2384 12.2515 17.0468 12.5911 17.7683C12.9308 18.4897 13.484 19.0893 14.1758 19.4859C14.8677 19.8824 15.6647 20.0567 16.4589 19.9852C17.2531 19.9136 18.0061 19.5996 18.6159 19.0858C19.2257 18.572 19.6628 17.8831 19.868 17.1126C20.0732 16.342 20.0366 15.527 19.7632 14.778C19.4897 14.0289 18.9926 13.382 18.3392 12.9249C17.6858 12.4678 16.9085 12.2226 16.1111 12.2225Z"
                    fill="#2E302E"
                  ></path>
                </g>
                <defs>
                  <clipPath id="clip0_141_11738">
                    <rect width="20" height="20" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <h4> {translations[language].EditProduct}</h4>
            </div>
            <div className="modal-body p-0 text-center">
              <div className="Edit_Product_Form">
                <input
                  type="hidden"
                  ref={menuIdRef}
                  value={productDetail?.menu_id}
                />
                <SelectField
                  InputName="Product Name"
                  Selectlabel={translations[language].ProductName}
                  options={productData}
                  onChange={handleProductChange}
                  ref={productRef}
                  db_name="name"
                  productDetail={productDetail ?? { name: "" }}
                />

                <SelectField
                  InputName="Term"
                  Selectlabel={translations[language].Term}
                  options={productTermsData}
                  ref={termRef}
                  onChange={handleTermChanges}
                  db_name="months"
                  productDetail={productDetail ?? { months: "" }}
                />
                {productDetail?.miles == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="Mileage"
                    Selectlabel={translations[language].Mileage}
                    options={productMilesData}
                    ref={mileRef}
                    onChange={handleMileChanges}
                    db_name="miles"
                    productDetail={productDetail ?? { miles: "" }}
                  />
                )}

                {productDetail?.service_interval == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="service_interval"
                    Selectlabel={translations[language].ServiceIntervals}
                    options={serviceIntervalData}
                    ref={serviceIntervalRef}
                    onChange={handleServiceIntervalChange}
                    db_name="service_interval"
                    productDetail={productDetail ?? { service_interval: "" }}
                  />
                )}

                {productDetail?.deductible == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="Deductible"
                    Selectlabel={translations[language].Deductible}
                    options={productDeductiblesData}
                    ref={deductibleRef}
                    onChange={handleOtherSelectChanges}
                    db_name="deductible"
                    productDetail={productDetail ?? { deductible: "" }}
                  />
                )}

                {productDetail?.tire_rotations == null ? (
                  ""
                ) : (
                  <SelectField
                    InputName="tire_rotations"
                    Selectlabel={translations[language].TireRotations}
                    options={tireRotationData}
                    ref={tireRotationRef}
                    onChange={handleOtherSelectChanges}
                    db_name="tire_rotations"
                    productDetail={productDetail ?? { tire_rotations: "" }}
                  />
                )}
              </div>
              <div className="Total_Price">
                <h4>{translations[language].TotalPrice}</h4>
                <h2>
                  {IsproductPriceLoading
                    ? translations[language].Updating
                    : `$${productPrice}`}
                </h2>
              </div>
            </div>
            <div className="modal-footer justify-content-center pb-0">
              <MyButton
                className="w-100 MyBtn"
                title={translations[language].SaveChange}
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleProductSubmit}
              ></MyButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardModals;
