import React, { useEffect, useState } from "react";
import { useLanguage } from "../../../LanguageContext";

import translations from "../../../translations.json";

const QuestionnaireHeader = ({ questionNumber }) => {
  const [firstName, setFirstName] = useState("");
  const { language } = useLanguage();

  useEffect(() => {
    // Fetch the first name from localStorage
    const storedFirstName = localStorage.getItem("firstName");
    if (storedFirstName) {
      setFirstName(storedFirstName);
    }
  }, []);

  const renderHrTags = () => {
    const hrTags = [];
    for (let i = 0; i < questionNumber; i++) {
      hrTags.push(<hr key={i} />);
    }
    return hrTags;
  };

  return (
    <>
      <div className="header">
        <h4>
          <span>{firstName}</span> {translations[language].OwnershipJourneyPoweredByAI}
        </h4>
        <div className="Hr_Box"> {renderHrTags()}</div>
      </div>
    </>
  );
};

export default QuestionnaireHeader;
