import React, { useState, useEffect, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FaPlay, FaPause } from "react-icons/fa";
import { MdOutlineReplay } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useVideo } from "../../VideoContext";
import { useAudio } from "../../AudioContext";
import { useLanguage } from "../../LanguageContext";

import translations from "../../translations.json";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import Loader from "../CommonFolder/Loader";
import Modal from "./Modal";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import MyButton from "../CommonFolder/MyButton";

import "./Deals.css";
import "../CommonFolder/DealsTableStructure/Table.css";

const DetailVerification = ({ setIsLogin }) => {
  const [dealData, setDealData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { language } = useLanguage();
  const { playPreloadedVideo, isVideoEnded2, isVideoEnded3 } = useVideo();
  const { id } = useParams();
  const { playClickSound } = useAudio();
  const navigate = useNavigate();

  const videoRef = useRef(null);
  const usertoken = localStorage.getItem("token");
  const half = "col-md-6 mb-3";

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://api.menuverse.ai/newdeal/show/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + usertoken,
          },
        }
      );
      if (!response.ok) {
        localStorage.clear();
        if (response.status === 401 || response.status === 403) {
          setIsLogin(false);
          // navigate("/login");
        }
        throw new Error(`Error: ${response.status}`);
      }
      const data = await response.json();
      setDealData(data.data);
      saveFirstNameToLocalStorage(data.data.firstName);
      saveEmailToLocalStorage(data.data.email);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const saveFirstNameToLocalStorage = (firstName) => {
    localStorage.setItem("firstName", firstName);
  };

  const saveEmailToLocalStorage = (email) => {
    localStorage.setItem("DealUserEmail", email);
  };

  const playVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    }
  };

  const pauseVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.pause();
      setIsPlaying(false);
    }
  };

  const replayVideo = () => {
    const video = videoRef.current;
    if (video) {
      video.currentTime = 0;
      video.play().catch((error) => {
        console.error("Error playing video:", error);
      });
      setIsPlaying(true);
    }
  };

  const handleContinueClick = () => {
    playClickSound();
    setIsPlaying(true);
    handleLoadedData("readDisclaimerVideoSrc", videoRef);
  };

  const handleModalClose = () => {
    handleLoadedData("dealDetailVideoSrc", videoRef);
  };

  useEffect(() => {
    if (isVideoEnded3) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEnded3]);

  useEffect(() => {
    if (isVideoEnded2) {
      setIsPlaying(false);
    } else {
      setIsPlaying(true);
    }
  }, [isVideoEnded2]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const modalElement = document.getElementById("DisclaimerModal");
    const modalElementforTakeMoment = document.getElementById("LetTakeMoment");
    if (modalElementforTakeMoment && modalElement) {
      if (!modalElementforTakeMoment.classList.contains("show")) {
        modalElement.addEventListener("hidden.bs.modal", handleModalClose);
      }

      modalElementforTakeMoment.addEventListener(
        "shown.bs.modal",
        handleModalOpen
      );

      return () => {
        modalElement.removeEventListener("hidden.bs.modal", handleModalClose);
        // modalElementforTakeMoment.removeEventListener("shown.bs.modal", handleModalOpen);
      };
    }
  }, []);

  const handleLoadedData = (videoSrc, ref) => {
    setLoading(false);
    playPreloadedVideo(videoSrc, ref);
  };

  const handleError = () => {
    setLoading(false);
  };

  // Use that function to format the phone number before passing it to the InputFiled component.

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";

    const cleaned = phoneNumber.replace(/\D/g, ""); // Remove non-digit characters
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }

    return phoneNumber; // Return raw if it doesn't match the expected length
  };

  return (
    <>
      <section className="Body_Section">
        <div className="container-fluid px-lg-20">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="Full_Table Detail_Verification_Table">
                <TableHeader
                  title={translations[language].ABCDealerGroup}
                  Monthlyfinance={true}
                />

                <div className="Full_Detail_Verification_Table">
                  <div className="container Add_Dealer">
                    {loading ? (
                      <div className="loader_Section">
                        <Loader />
                      </div>
                    ) : (
                      <div className="row">
                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="CustomerName"
                          lableValue={translations[language].CustomerName}
                          value={
                            dealData
                              ? `${dealData.firstName} ${dealData.middleName} ${dealData.lastName}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="VehicleColor"
                          lableValue={translations[language].VehicleColor}
                          value={
                            dealData && dealData.VehicleColor !== null
                              ? dealData.vehicleColor
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="PhoneNumber"
                          lableValue={translations[language].PhoneNumber}
                          value={
                            dealData && dealData.phoneNumber !== null
                              ? formatPhoneNumber(dealData.phoneNumber)
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="FeeAddons"
                          lableValue={translations[language].FeeAddOns}
                          value={
                            dealData && dealData.free_add_on !== null
                              ? `$ ${dealData.free_add_on}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="email"
                          InputName="Email"
                          lableValue={translations[language].Email}
                          value={
                            dealData && dealData.email !== null
                              ? dealData.email
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Taxes"
                          lableValue={translations[language].Taxes}
                          value={
                            dealData && dealData.taxes !== null
                              ? `$ ${dealData.taxes}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Address"
                          lableValue={translations[language].Address}
                          value={
                            dealData && dealData.streetAddress !== null
                              ? dealData.streetAddress
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="LoanTerm"
                          lableValue={translations[language].LoanTerm}
                          value={
                            dealData && dealData.loan_term !== null
                              ? `${dealData.loan_term} Months`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="VINnumber"
                          lableValue={translations[language].VINnumber}
                          value={dealData ? dealData.vinNumber : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="InterestRate"
                          lableValue={translations[language].InterestRate}
                          value={
                            dealData && dealData.interest_rate !== null
                              ? `${dealData.interest_rate} %`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="DealNumber"
                          lableValue={translations[language].DealNumber}
                          value={dealData ? dealData.deal_number : ""}
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="tel"
                          InputName="PreviousVehiclepayment"
                          lableValue={
                            translations[language].PreviousVehiclePayment
                          }
                          value="0"
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Purchaseprice"
                          lableValue={translations[language].Purchaseprice}
                          value={
                            dealData && dealData.purchase_price !== null
                              ? `$ ${dealData.purchase_price}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="NewVehiclepayment"
                          lableValue={translations[language].NewVehiclepayment}
                          value={
                            dealData &&
                            dealData.new_vehicle_payment !== null &&
                            dealData.new_vehicle_payment !== undefined
                              ? `$ ${dealData.new_vehicle_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="DownPayment"
                          lableValue={translations[language].DownPayment}
                          value={
                            dealData && dealData.down_payment !== null
                              ? `$ ${dealData.down_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="ProtectionItems"
                          lableValue={translations[language].ProtectionItems}
                          value={
                            dealData && dealData.protection_items !== null
                              ? `$ ${dealData.protection_items}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="NetTrade"
                          lableValue={translations[language].NetTrade}
                          value={
                            dealData && dealData.net_trade !== null
                              ? `$ ${dealData.net_trade}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="TotalMonthlyPayment"
                          lableValue={
                            translations[language].TotalMonthlyPayment
                          }
                          value={
                            dealData && dealData.total_monthly_payment !== null
                              ? `$ ${dealData.total_monthly_payment}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="text"
                          InputName="Rebate"
                          lableValue={translations[language].Rebate}
                          value={
                            dealData && dealData.rebate !== null
                              ? `$ ${dealData.rebate}`
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="date"
                          InputName="contract_date"
                          lableValue={translations[language].ContractDate}
                          value={
                            dealData && dealData.contract_date !== null
                              ? dealData.contract_date
                              : ""
                          }
                        />

                        <InputFiled
                          readOnly
                          InputBoxClassname={half}
                          InputType="date"
                          InputName="inService_date"
                          lableValue={translations[language].InServiceDate}
                          value={
                            dealData && dealData.inService_date !== null
                              ? dealData.inService_date
                              : ""
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="Table_foot pe-5">
                    <div className="Foot_btn pe-5">
                      <button
                        className="Secondry_Btn"
                        data-bs-toggle="modal"
                        data-bs-target="#DisclaimerModal"
                        onClick={handleContinueClick}
                      >
                        {translations[language].Continue}
                      </button>

                      <NavLink to={`/EditDeal/${id}`}>
                        <MyButton title={translations[language].EditDetails} />
                      </NavLink>
                    </div>
                  </div>
                </div>

                {!isModalOpen && (
                  <div className="DetailVerification_Versa ">
                    <div className="DetailVerification_Versa_Bottons">
                      {isPlaying ? (
                        <button onClick={pauseVideo}>
                          <FaPause />
                        </button>
                      ) : (
                        <button onClick={playVideo}>
                          <FaPlay />
                        </button>
                      )}
                      <button onClick={replayVideo}>
                        <MdOutlineReplay />
                      </button>
                    </div>

                    <div className="Bot_Buttons Big-size-background-Ai-video">
                      {loading && <Loader />}
                      {
                        <>
                          <video
                            key="video1"
                            ref={videoRef}
                            onError={handleError}
                          ></video>
                          <img
                            className="Big-size-background-Ai"
                            src="/assets/Images/DashboardImages/newversaNew.jpg"
                            alt=""
                            onLoad={() => {
                              handleLoadedData("dealDetailVideoSrc", videoRef);
                            }}
                          />
                        </>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal />
      </section>
    </>
  );
};

export default DetailVerification;
