import React, { useEffect, useState, createContext } from "react";
import { Route, Routes, useLocation, Navigate, useNavigate, useParams } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import Navbar from "./Components/NavBar/Navbar";
import LoginLayout from "./Components/LoginPages/LoginLayout";
import DetailVerification from "./Components/Deals/DetailVerification";
import Deals from "./Components/Deals/Deals";
import AddDeal from "./Components/Deals/AddDeal";
import EditDeal from "./Components/Deals/EditDeal";
import Presentation from "./Components/Questionnaire/Presentation";
import Questionnaire from "./Components/Questionnaire/Questionnaire";
import SelectCharity from "./Components/SelectCharity/SelectCharity";
import Dashboard from "./Components/Dashboard/Dashboard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Components/LoginPages/ProtectedRoute";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { IoArrowBackOutline } from "react-icons/io5";
import Cookies from "js-cookie";
import { useAudio } from "./AudioContext";
import { LanguageProvider } from "./LanguageContext";
export const AudioContext = createContext();

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const handle = useFullScreenHandle();
  const [isLogin, setIsLogin] = useState(false);
  const [hideNavbar, setHideNavbar] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isShared, setIsShared] = useState(false);
  const { playClickSound } = useAudio();
  const { dealId } = useParams();
  const [IsPinHasInId, setIsPinHasInId] = useState();
  const [selectedValueForWaiting, setselectedValueForWaiting] = useState();

  useEffect(() => {
    const cleanupModals = () => {
      // Remove all backdrops
      const backdrops = document.querySelectorAll(".modal-backdrop.show");
      backdrops.forEach((backdrop) => backdrop.remove());

      // Remove modal-open class and styles from the body
      const body = document.body;
      if (body.classList.contains("modal-open")) {
        body.classList.remove("modal-open");
      }
      body.style.overflow = "";
      body.style.paddingRight = "";
    };

    return () => {
      cleanupModals();
    };
  }, [location]);

  useEffect(() => {
    const pathsWithoutNavbar = ["/login", "/SendCode", "/VerifyCode", "/ChangePassword"];
    if (pathsWithoutNavbar.includes(location.pathname)) {
      setHideNavbar(true);
    } else {
      setHideNavbar(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");

    if (token && userId && userName) {
      setIsLogin(true);
    } else {
      const cookieToken = Cookies.get("token");
      const userId = Cookies.get("userId");
      const userName = Cookies.get("userName");

      if (userId && cookieToken && userName) {
        localStorage.setItem("userId", userId);
        localStorage.setItem("token", cookieToken);
        localStorage.setItem("userName", userName);
        setIsLogin(true);
      }
    }
  }, []);

  useEffect(() => {
    const shared = localStorage.getItem("isShared") === "true";
    setIsShared(shared);
  }, []);

  const handleLogout = () => {
    window.location.reload();
    localStorage.clear();
    setIsLogin(false);
    Cookies.remove("email");
    Cookies.remove("password");
    Cookies.remove("rememberMe");
    Cookies.remove("token");
    Cookies.remove("userId");
    Cookies.remove("userName");
  };

  const handleBack = () => {
    playClickSound();
    navigate(-1);
  };

  useEffect(() => {
    const search = location.search;
    const parts = search.split("?=");

    const token = parts.length > 1 ? parts[1] : null;
    const userId = parts.length > 2 ? parts[2] : null;
    const userName = parts.length > 3 ? parts[3] : null;
    const isShared = "true";
    const dealId = location.pathname.split("/")[2];
    if (token && userId) {
      setIsLogin(true);
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("userName", userName);
      localStorage.setItem("isShared", isShared);
      localStorage.setItem("DealId", `${dealId}`);
      navigate(`/DetailVerification/${dealId}`);
      if (isShared) {
        window.location.reload();
      }
    }

    // Update document title based on the current route

    switch (location.pathname) {
      case `/dashboard/${dealId}`:
        document.title = "Dashboard | Menuverse";
        break;
      case `/AddDeal`:
        document.title = "Add Deal | Menuverse";
        break;
      case `/DetailVerification/${dealId}`:
        document.title = "Detail Verification | Menuverse";
        break;
      case `/EditDeal/${dealId}`:
        document.title = "Edit Deal | Menuverse";
        break;
      case `/Presentation/${dealId}`:
        document.title = "Presentation | Menuverse";
        break;
      case `/Questionnaire/${dealId}`:
        document.title = "Questionnaire | Menuverse";
        break;
      case "/SendCode":
        document.title = "SendCode";
        break;
      case "/login":
        document.title = "Login";
        break;
      case "/VerifyCode":
        document.title = "VerifyCode";
        break;
      case "/ChangePassword":
        document.title = "ChangePassword";
        break;
      default:
        document.title = "Menuverse";
    }
  }, [location.search, location.pathname]);

  useEffect(() => {
    // Key name in localStorage
    const savedPrizesKey = "savedPrizes";

    // Get saved prizes from localStorage
    const savedPrizes = JSON.parse(localStorage.getItem(savedPrizesKey)) || [];

    // Check if the page for this deal ID is opened
    if (window.location.pathname.includes(`/Questionnaire/${dealId}`)) {
      // Mark this deal as visited
      localStorage.setItem(`visited_${dealId}`, true);
    }

    // Function to remove savedPrizes from localStorage if the deal page is not opened
    const removeUnvisitedData = () => {
      savedPrizes.forEach((prize) => {
        if (!localStorage.getItem(`visited_${dealId}`)) {
          // If this deal ID was not visited, remove from localStorage
          localStorage.removeItem(savedPrizesKey);
        }
      });
    };

    // Call the function to remove data if page is not opened
    removeUnvisitedData();

    // Cleanup function to remove the visit status on component unmount
    return () => {
      localStorage.removeItem(`visited_${dealId}`);
    };
  }, [dealId]);

  return (
    <>
      <FullScreen handle={handle}>
        <LanguageProvider>
          {!hideNavbar && <Navbar handle={handle} onLogout={handleLogout} IsPinHasInId={IsPinHasInId} setIsPinHasInId={setIsPinHasInId} selectedValueForWaiting={selectedValueForWaiting} setselectedValueForWaiting={setselectedValueForWaiting} />}
          <Routes>
            <Route path="/*" element={<LoginLayout isLogin={isLogin} setIsPinHasInId={setIsPinHasInId} />} />
            <Route
              path="/Dashboard/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />

            {!isShared && (
              <>
                <Route
                  path="/AddDeal"
                  element={
                    <ProtectedRoute isLogin={isLogin}>
                      <AddDeal setIsLogin={setIsLogin} setselectedValueForWaiting={setselectedValueForWaiting} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <ProtectedRoute isLogin={isLogin}>
                      <Deals setIsLogin={setIsLogin} setselectedValueForWaiting={setselectedValueForWaiting} />
                    </ProtectedRoute>
                  }
                />
              </>
            )}
            <Route
              path="/EditDeal/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <EditDeal setIsLogin={setIsLogin} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/DetailVerification/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <DetailVerification setIsLogin={setIsLogin} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Presentation/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <Presentation />
                </ProtectedRoute>
              }
            />
            <Route
              path="/Questionnaire/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <Questionnaire setIsLogin={setIsLogin} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/SelectCharity/:id"
              element={
                <ProtectedRoute isLogin={isLogin}>
                  <SelectCharity />
                </ProtectedRoute>
              }
            />
            <Route path="/*" element={<Navigate to={isLogin ? "/" : "/login"} />} />
          </Routes>
          <ToastContainer />
          <IoArrowBackOutline onClick={handleBack} className="Back_Page_btn" />
        </LanguageProvider>
      </FullScreen>
    </>
  );
}
