import React from "react";
import { useAudio } from "../../AudioContext";

const MyButton = ({ type, title, onModalOpen, children, onClick, ...rest }) => {
  const { playClickSound } = useAudio();

  return (
    <button
      type={type}
      className="MyBtn"
      {...rest}
      onClick={() => {
        playClickSound();
        if (onClick) {
          onClick();
        }
        if (onModalOpen) {
          onModalOpen();
        }
      }}
    >
      {title}
      {children}
    </button>
  );
};

export default MyButton;
