import React, { createContext, useContext, useState, useEffect } from "react";

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    // Retrieve language from local storage or default to "en"
    return localStorage.getItem("language") || "en";
  });

  const toggleLanguage = () => {
    setLanguage((prevLanguage) => {
      const newLanguage = prevLanguage === "en" ? "es" : "en";
      localStorage.setItem("language", newLanguage); // Save to local storage
      return newLanguage;
    });
  };

  // Save the language to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return <LanguageContext.Provider value={{ language, toggleLanguage }}>{children}</LanguageContext.Provider>;
};
