import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ABCDealerImage from "../CommonFolder/CommonImages/ABCDealerImage";
import MenuVersaImage from "../CommonFolder/CommonImages/MenuVersaImage";
import SendCode from "./LoginInnerPages/SendCode";
import Login from "./LoginInnerPages/Login";
import VerifyCode from "./LoginInnerPages/VerifyCode";
import ChangePassword from "./LoginInnerPages/ChangePassword";
import Loader from "../CommonFolder/Loader";
import "./LoginPages.css";

const LoginLayout = ({ isLogin, setIsPinHasInId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isGifLoaded, setGifLoaded] = useState(false);
  const [isAudioLoaded, setAudioLoaded] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);

  useEffect(() => {
    if (isGifLoaded && isAudioLoaded) {
      setIsLoading(false);
    }
  }, [isGifLoaded, isAudioLoaded]);

  const handleProceed = () => {
    setLoadLogin(true);
  };

  const handleVideoLoaded = () => {
    setIsLoading(false);
  };

  return (
    <>
      {!isLogin ? (
        <section id="login_Sectoin">
          <div className={`container ${loadLogin ? "" : "loadLoginSection"}`}>
            {loadLogin ? (
              <div className="row">
                <div className="col-lg-7 Powered_Container">
                  {isLoading && <Loader />}

                  <div className="background-video rounded-0" style={{ display: isLoading ? "none" : "block" }}>
                    <video onCanPlayThrough={handleVideoLoaded} style={{ display: isLoading ? "none" : "block" }} autoPlay loop playsInline webkit-playsInline>
                      <source src="../assets/Videos/menuverse-gif.webm" type="video/webm" />
                      <source src="../assets/Videos/menuverse-gif.mp4" type="video/mp4" />
                      browser does not support
                    </video>
                  </div>
                </div>
                <div className="col-lg-5 Form_Container">
                  <ABCDealerImage />
                  <Routes>
                    <Route path="login" element={<Login setIsPinHasInId={setIsPinHasInId} />} />
                    <Route path="sendCode" element={<SendCode />} />
                    <Route path="verifyCode" element={<VerifyCode />} />
                    <Route path="changePassword" element={<ChangePassword />} />
                    <Route path="*" element={<Navigate to="login" />} />
                  </Routes>
                  <MenuVersaImage />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-7 "></div>
                <div className="col-lg-5 ">
                  <button className="proceedToLogin" onClick={handleProceed}>
                    Proceed
                  </button>
                </div>
              </div>
            )}
          </div>
        </section>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default LoginLayout;
