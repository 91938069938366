import React from "react";

const LockSvg = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 45 45" fill="none">
        <g clipPath="url(#clip0_774_4952)">
          <g clipPath="url(#clip1_774_4952)">
            <path
              d="M35.8333 16.444V13.8333C35.8333 6.74569 30.0877 1 23 1C15.9123 1 10.1667 6.74569 10.1667 13.8333V16.444C6.83009 17.9002 4.67151 21.1928 4.66669 24.8333V35.8333C4.67271 40.8934 8.77322 44.994 13.8333 45H32.1666C37.2267 44.994 41.3272 40.8934 41.3333 35.8333V24.8333C41.3286 21.1928 39.1699 17.9002 35.8333 16.444ZM24.8333 32.1667C24.8333 33.1792 24.0125 34 23 34C21.9875 34 21.1667 33.1792 21.1667 32.1667V28.5C21.1667 27.4875 21.9875 26.6667 23 26.6667C24.0125 26.6667 24.8333 27.4875 24.8333 28.5V32.1667ZM32.1667 15.6667H13.8333V13.8334C13.8333 8.77081 17.9373 4.6667 23 4.6667C28.0627 4.6667 32.1667 8.77073 32.1667 13.8334V15.6667Z"
              fill="#2E302E"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_774_4952">
            <rect width="45" height="45" fill="white" />
          </clipPath>
          <clipPath id="clip1_774_4952">
            <rect width="44" height="44" fill="white" transform="translate(1 1)" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LockSvg;
