import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "../../LanguageContext";

import axios from "axios";

import Modal from "../Deals/Modal";
import MyButton from "../CommonFolder/MyButton";
import translations from "../../translations.json";
import InputFiled from "../CommonFolder/FormStructure/InputField";
import SelectField from "../CommonFolder/FormStructure/SelectField";
import TableHeader from "../CommonFolder/DealsTableStructure/TableHeader";
import validationRules from "../CommonFolder/FormStructure/validationRules.json";

const AddDeal = ({ setIsLogin, setselectedValueForWaiting }) => {
  const [stateData, setstateData] = useState([]);
  const [cityData, setcityData] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { language, toggleLanguage } = useLanguage();
  const navigate = useNavigate();
  const hasMounted = useRef(false);

  const usertoken = localStorage.getItem("token");
  let half = "mb-3 DisabledInputs";

  const [formData, setFormData] = useState({
    id: "",
    firstName: "",
    middleName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    streetAddress: "",
    city: 0,
    state: 0,
    zipCode: "",
    vehicle: "",
    vinNumber: "",
    vehicleColor: "",
    contract_date: "",
    inService_date: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://api.menuverse.ai/states/show",
          {
            headers: {
              Authorization: `Bearer ${usertoken}`,
            },
          }
        );
        setstateData(response.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Check if a valid state is selected before calling the API
        if (formData.state) {
          const response = await axios.post(
            "https://api.menuverse.ai/cities/showbystate",
            { state: formData.state }, // Pass the state ID here
            {
              headers: {
                Authorization: `Bearer ${usertoken}`,
              },
            }
          );
          setcityData(response.data.data);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [formData.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    let parsedValue = value;

    if (name === "phoneNumber") {
      let numericValue = value.replace(/\D/g, "");
      // Format phone number as the user types
      if (numericValue.length > 3 && numericValue.length <= 6) {
        numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
      } else if (numericValue.length > 6) {
        numericValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(
          3,
          6
        )}-${numericValue.slice(6, 10)}`;
      }

      parsedValue = numericValue;
    } else if (name === "city" || name === "state") {
      parsedValue = parseInt(value, 10);
    } else if (name === "phoneNumber" || name === "zipCode") {
      parsedValue = value.replace(/\D/g, "");
    }

    setFormData({ ...formData, [name]: parsedValue });

    // When the state is selected, filter cities based on selected state
    if (name === "state") {
      const matchingCities = cityData.filter(
        (city) => city.state_id === parseInt(value, 10)
      );
      setFilteredCities(matchingCities); // Update filtered city list based on selected state
      if (matchingCities.length > 0) {
        // console.log("cities matched", matchingCities);
      } else {
        setFilteredCities([
          {
            id: 1,
            name: "",
          },
        ]); // Update filtered city list based on selected state
      }
      setFormData({ ...formData, state: parsedValue, city: 0 }); // Reset city field when state changes
    }
  };

  useEffect(() => {
    if (hasMounted.current && isFormSubmitted) {
      validateForm();
    } else {
      hasMounted.current = true;
    }
  }, [toggleLanguage]);

  const validateForm = () => {
    setIsFormSubmitted(true);
    const newErrors = {};

    for (const [field, rules] of Object.entries(validationRules)) {
      // Only validate if the field exists in formData (i.e., it's part of the form)

      if (formData[field] !== undefined) {
        let value = formData[field];

        if (field === "phoneNumber") {
          value = value.replace(/\D/g, "");
        }

        if (field === "middleName" && !value) continue;
        if (field === "vinNumber" && !value) continue;
        const errorMessages = rules.messages[language] || rules.messages.en;
        if (rules.required && !value) {
          newErrors[field] = errorMessages.required;
        } else if (rules.minLength && value.length < rules.minLength) {
          newErrors[field] = errorMessages.minLength;
        } else if (rules.maxLength && value.length > rules.maxLength) {
          newErrors[field] = errorMessages.maxLength;
        } else if (rules.pattern && !new RegExp(rules.pattern).test(value)) {
          newErrors[field] = errorMessages.pattern;
        } else if (rules.length && value.length !== rules.length) {
          newErrors[field] = errorMessages.length;
        }
      }
    }

    // Check if select fields are selected

    if (!formData.city)
      newErrors.city =
        language === "es"
          ? "Por favor seleccione una ciudad."
          : "Please select a city.";
    if (!formData.state)
      newErrors.state =
        language === "es"
          ? "Por favor seleccione un estado."
          : "Please select a state.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const roleId = Number(localStorage.getItem("roleId"));
    const userId = Number(localStorage.getItem("userId"));
    const LoginWitheEmail = localStorage.getItem("LoginWitheEmail");

    const rawPhoneNumber = formData.phoneNumber
      ? formData.phoneNumber.replace(/\D/g, "")
      : "";

    if (!validateForm()) {
      toast.dismiss();
      // toast.warn("Failed to add the deal. Check details.");
      return;
    }
    // console.log("api called and run");
    fetch("https://api.menuverse.ai/newdeal/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + usertoken,
      },
      body: JSON.stringify({
        ...formData,
        phoneNumber: rawPhoneNumber, // Send the raw phone number

        // email: LoginWitheEmail,
        ...(roleId === 2
          ? { dealer_id: Number(localStorage.getItem("dealer_id")) }
          : roleId === 3
          ? {
              dealer_id: Number(localStorage.getItem("dealer_id")),
              sales_person: userId,
              sales_person2: userId,
            }
          : roleId === 4
          ? {
              business_manager: userId,
              sales_manager: userId,
              dealer_id: Number(localStorage.getItem("dealer_id")),
            }
          : {}),
      }),
    })
      .then((response) => {
        if (!response.ok) {
          localStorage.clear();
          if (response.status === 401 || response.status === 403) {
            setIsLogin(false);
            // navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const dealId = data.data.id;
        toast.dismiss();
        setselectedValueForWaiting(30);
        toast.success(translations[language]?.DealAddedSuccessfully);
        navigate(`/DetailVerification/${dealId}`);
      })
      .catch((error) => {
        toast.dismiss();
        toast.warn(translations[language]?.FailedToAddDeal);
      });
  };

  return (
    <section className="Body_Section">
      <div className="container-fluid px-lg-20">
        <div className="row justify-content-center ">
          <div className="col-lg-9">
            <div className="Full_Table">
              <TableHeader
                title={translations[language].ABCDealerGroup}
                showSareLink={true}
              />
              <form
                onSubmit={handleSubmit}
                className="needs-validation"
                id="Add-Deal-Form"
                noValidate
              >
                <div className="container Add_Dealer">
                  <div className="row row-cols-lg-2 row-cols-md-1">
                    {/* First Name */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="firstName"
                        lableValue={translations[language].FirstName}
                        required
                        value={formData.firstName}
                        onChange={handleChange}
                        errorsMSG={errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="Add-Deal-Error-msg">{errors.firstName}</p>
                      )}
                    </div>

                    {/* Middle Name */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="middleName"
                        lableValue={translations[language].MiddleName}
                        value={formData.middleName}
                        onChange={handleChange}
                        errorsMSG={errors.middleName}
                      />
                      {errors.middleName && (
                        <p className="Add-Deal-Error-msg">
                          {errors.middleName}
                        </p>
                      )}
                    </div>

                    {/* Last Name */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="lastName"
                        lableValue={translations[language].LastName}
                        required
                        value={formData.lastName}
                        onChange={handleChange}
                        errorsMSG={errors.lastName}
                      />
                      {errors.lastName && (
                        <p className="Add-Deal-Error-msg">{errors.lastName}</p>
                      )}
                    </div>

                    {/* Phone Number */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="tel"
                        InputName="phoneNumber"
                        lableValue={translations[language].PhoneNumber}
                        required
                        value={formData.phoneNumber}
                        onChange={handleChange}
                        errorsMSG={errors.phoneNumber}
                        maxlength="15"
                      />
                      {errors.phoneNumber && (
                        <p className="Add-Deal-Error-msg">
                          {errors.phoneNumber}
                        </p>
                      )}
                    </div>

                    {/* Email */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="email"
                        InputName="email"
                        lableValue={translations[language].Email}
                        required
                        value={formData.email}
                        onChange={handleChange}
                        errorsMSG={errors.email}
                      />
                      {errors.email && (
                        <p className="Add-Deal-Error-msg">{errors.email}</p>
                      )}
                    </div>

                    {/* Street Address */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="streetAddress"
                        lableValue={translations[language].StreetAddress}
                        required
                        value={formData.streetAddress}
                        onChange={handleChange}
                        errorsMSG={errors.streetAddress}
                      />
                      {errors.streetAddress && (
                        <p className="Add-Deal-Error-msg">
                          {errors.streetAddress}
                        </p>
                      )}
                    </div>

                    {/* State */}
                    <div className="position-relative">
                      <SelectField
                        className={`${half} Select-required`}
                        InputName="state"
                        Selectlabel={translations[language].State}
                        value={formData.state}
                        onChange={handleChange}
                        errorsMSG={errors.state}
                        options={stateData.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      />
                      {errors.state && (
                        <p className="Add-Deal-Error-msg">{errors.state}</p>
                      )}
                    </div>

                    {/* City */}
                    <div className="position-relative">
                      <SelectField
                        className={`${half} Select-required`}
                        InputName="city"
                        Selectlabel={translations[language].City}
                        value={formData.city}
                        onChange={handleChange}
                        errorsMSG={errors.city}
                        options={cityData.map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))}
                      />
                      {errors.city && (
                        <p className="Add-Deal-Error-msg">{errors.city}</p>
                      )}
                    </div>

                    {/* Zip Code */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="zipCode"
                        lableValue={translations[language].ZipCode}
                        required
                        value={formData.zipCode}
                        onChange={handleChange}
                        errorsMSG={errors.zipCode}
                        maxlength="12"
                        minlength="5"
                      />
                      {errors.zipCode && (
                        <p className="Add-Deal-Error-msg">{errors.zipCode}</p>
                      )}
                    </div>

                    {/* Contract Date */}
                    <div className="position-relative">
                      <InputFiled
                        required
                        InputBoxClassname={half}
                        InputType="date"
                        InputName="contract_date"
                        lableValue={translations[language].ContractDate}
                        value={formData.contract_date}
                        onChange={handleChange}
                      />
                      {errors.contract_date && (
                        <p className="Add-Deal-Error-msg">
                          {errors.contract_date}
                        </p>
                      )}
                    </div>

                    {/* In Service Date */}
                    <div className="position-relative">
                      <InputFiled
                        required
                        InputBoxClassname={half}
                        InputType="date"
                        InputName="inService_date"
                        lableValue={translations[language].InServiceDate}
                        value={formData.inService_date}
                        onChange={handleChange}
                      />
                      {errors.inService_date && (
                        <p className="Add-Deal-Error-msg">
                          {errors.inService_date}
                        </p>
                      )}
                    </div>

                    {/* Vehicle */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="vehicle"
                        lableValue={translations[language].Vehicle}
                        value={formData.vehicle}
                        onChange={handleChange}
                      />
                    </div>

                    {/* VIN Number */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        maxlength="17"
                        InputName="vinNumber"
                        lableValue={translations[language].VinNumber}
                        value={formData.vinNumber}
                        onChange={handleChange}
                      />
                      {errors.vinNumber && (
                        <p className="Add-Deal-Error-msg">{errors.vinNumber}</p>
                      )}
                    </div>

                    {/* Vehicle Color */}
                    <div className="position-relative">
                      <InputFiled
                        InputBoxClassname={half}
                        InputType="text"
                        InputName="vehicleColor"
                        lableValue={translations[language].VehicleColor}
                        value={formData.vehicleColor}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="Table_foot justify-content-end">
                    <div className="Foot_btn">
                      <MyButton
                        type="submit"
                        title={translations[language].CreateDeal}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal />
    </section>
  );
};

export default AddDeal;
