import React, { useState, useEffect } from "react";
import { useAudio } from "../../../../AudioContext";
import { useLanguage } from "../../../../LanguageContext";

import translations from "../../../../translations.json";

const FAQComponent = (props) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { playClickSound } = useAudio();
  const { language } = useLanguage();

  useEffect(() => {
    setActiveIndex(null);
  }, [props.activeButton]);

  const toggleAccordion = (index, videoURL) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      props.setVideoURL("");
      props.onInexChange(null);
    } else {
      setActiveIndex(index);
      props.setVideoURL(videoURL);
      props.onInexChange(index, videoURL);
    }
  };

  return (
    <>
      <div className="accordion">
        {props.faqs.map((faqGroup, index) => {
          if (faqGroup.title === props.activeButton) {
            return (
              <div key={index}>
                {faqGroup.faqs.map((faq, faqIndex) => (
                  <div className="accordion-item" key={faqIndex} onClick={playClickSound}>
                    <button className={`accordion-button ${faqIndex === activeIndex ? "" : "collapsed"}`} type="button" onClick={() => toggleAccordion(faqIndex, faq.videoURL)} aria-expanded={faqIndex === activeIndex ? "true" : "false"} aria-controls={`collapse${index}-${faqIndex}`}>
                      {language === "en" ? faq.question : faq.translations.es.question}
                    </button>
                    <div id={`collapse${index}-${faqIndex}`} className={`accordion-collapse collapse ${faqIndex === activeIndex ? "show" : ""}`}>
                      <div className="accordion-body">
                        <p>{language === "en" ? faq.answer : faq.translations.es.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </>
  );
};

export default FAQComponent;
