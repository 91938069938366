import React from "react";
import { useLanguage } from "../../../../LanguageContext";

import translations from "../../../../translations.json";

const TermAmountCalulation = ({ emi, interest, term, down, IsDealType }) => {
  const { language } = useLanguage();

  return (
    <>
      <div className="Term_Amount_Items">
        {!IsDealType ? (
          <>
            <div>
              <span className="termRateLable">{translations[language].TermRate}</span>
              <span className="TotalTermRateValue Value">
                {term}
                <span className="RateValue">@ {interest}%</span>
              </span>
            </div>
            <div>
              <span className="RetailPMTLable"> {translations[language].Retailpymt}</span>
              <span className="RetailPMTValue Value">${parseFloat(emi).toFixed(2)}</span>
            </div>
            <div>
              <span className="AmtDownLable">{translations[language].Amtdown}</span>
              <span className="AmtDownValue Value">${down}</span>
            </div>
            <div>
              <span className="DisPMTLable"> {translations[language].Discpymt}</span>
              <span className="DisPMTvalue Value">$0.00</span>
            </div>
          </>
        ) : (
          <>
            <div>
              <span className="AmtDownLable">{translations[language].TotalCost}</span>
              <span className="AmtDownValue Value">${down}</span>
            </div>
            <div>
              <span className="DisPMTLable">{translations[language].Retailpymt}</span>
              <span className="DisPMTvalue Value">${emi}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TermAmountCalulation;
