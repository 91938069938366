import React, { useState, forwardRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { HiPlus } from "react-icons/hi2";
import { useAudio } from "../../../../AudioContext";
import { useLanguage } from "../../../../LanguageContext";

import translations from "../../../../translations.json";
import "../../Dashboard.css";

const Seviceoptions = forwardRef(({ ProductId, WarrantyImages, ServicesTitle, OptionMSG, onServiceClick, ondltproductClick, onRemoveProduct, menuData, onClickProduct }, ref) => {
  const [productTitle, setProductTitle] = useState();

  const { language } = useLanguage();
  const { playClickSound } = useAudio();

  const productNames = {
    GAP: "GAP",
    "Windshield Protection": "windshield_protection",
    "Key/Remote Replacement": "key_replacement",
    "Vehicle Service Contract": "VSC",
    "Paintless Dent Repair": "PDR",
    "Pre-paid Maintenance": "PPM",
    "Anti-theft": "anti_theft",
    "Paint Protection": "paint_protection",
    "Tire & Wheel Protection": "TWL",
    "TotalCare Shield": "TB",
  };

  const handleClick = () => {
    playClickSound();
    const productId = menuData[productNames[ServicesTitle]]?.menu_id;
    if (productId) {
      onClickProduct(productId);
    }

    onServiceClick(ServicesTitle);
    ondltproductClick(ProductId);
    // setTimeout(() => {
    //   HandletriviaTextVideo(ProductId);
    // }, 4000);
  };

  const AddProduct = (event) => {
    playClickSound();
    const productDbId = menuData[productNames[ServicesTitle]]?.menu_id;

    const productData = {
      productDbId: productDbId,
      ProductId: ProductId,
    };

    event.stopPropagation();
    onRemoveProduct(productData);
  };

  useEffect(() => {
    const matchTitle = {
      WindshieldProtection: "Windshield Protection",
      KeyRemoteReplacement: "Key/Remote Replacement",
      VehicleServiceContract: "Vehicle Service Contract",
      PaintlessDentRepair: "Paintless Dent Repair",
      PrePaidMaintenance: "Pre-paid Maintenance",
      AntiTheft: "Anti-theft",
      PaintProtection: "Paint Protection",
      TireWheelProtection: "Tire & Wheel Protection",
      TotalCareShield: "TotalCare Shield",
    };

    const matchedKey = Object.keys(matchTitle).find((key) => matchTitle[key] === ServicesTitle);

    if (matchedKey) {
      setProductTitle(matchedKey);
    } else {
      setProductTitle(null);
    }
  }, [ServicesTitle]);

  return (
    <>
      <div className="service-options">
        <div ref={ref} className={`option-With-Text ${ProductId}`} onClick={handleClick}>
          <HiPlus className="Add-Product-Icon" onClick={AddProduct} />
          <NavLink data-bs-toggle="modal" data-bs-target="#TotalCareShieldAndComman">
            <div className="Option clickTarget">
              <img className="clickTarget" src={`/assets/Images/DashboardImages/${WarrantyImages}`} alt="" />
              <span className="clickTarget">{OptionMSG}</span>
            </div>
            {/* Conditionally render the appropriate <p> tag */}
            {productTitle ? <p>{translations[language][productTitle]}</p> : <p>{ServicesTitle}</p>}
          </NavLink>
        </div>
      </div>
    </>
  );
});

export default Seviceoptions;
