import React, { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import InputField from "../../CommonFolder/FormStructure/InputField";
import MyButton from "../../CommonFolder/MyButton";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAudio } from "../../../AudioContext";
import Cookies from "js-cookie";
import validationRules from "../../CommonFolder/FormStructure/validationRules.json";

const Login = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const usertoken = localStorage.getItem("token");
  const { playClickSound } = useAudio();
  const [rememberMe, setRememberMe] = useState(false);

  const validateForm = () => {
    const newErrors = {};

    // Validate email
    if (validationRules.email) {
      const emailRules = validationRules.email;
      if (emailRules.required && !email) {
        newErrors.email = emailRules.messages.required;
      } else if (emailRules.minLength && email.length < emailRules.minLength) {
        newErrors.email = emailRules.messages.minLength;
      } else if (emailRules.maxLength && email.length > emailRules.maxLength) {
        newErrors.email = emailRules.messages.maxLength;
      } else if (
        emailRules.pattern &&
        !new RegExp(emailRules.pattern).test(email)
      ) {
        newErrors.email = emailRules.messages.pattern;
      }
    }

    // Validate password
    if (validationRules.password) {
      const passwordRules = validationRules.password;
      if (passwordRules.required && !password) {
        newErrors.password = passwordRules.messages.required;
      }
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    // Run validation
    if (!validateForm()) {
      return; // If validation fails, stop further execution
    }
    try {
      const response = await fetch("https://api.menuverse.ai/users/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + usertoken,
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        toast.dismiss();
        toast.warn("Invalid credentials.");

        throw new Error(data.message || "Login failed");
      }

      toast.dismiss();
      toast.success("Login successful!");
      props.setIsPinHasInId(data.data.pin);
      // console.log("login response.data", data.data.pin);
      // Assuming the API response structure is as follows
      const { id, name, token, roleId } = data.data;

      localStorage.setItem("userId", id.toString());
      localStorage.setItem("userName", name);
      localStorage.setItem("token", token);
      localStorage.setItem("roleId", roleId);
      localStorage.setItem("LoginWitheEmail", data.data.email);
      localStorage.setItem("dealer_sign", data.data.dealer_sign);
      localStorage.setItem("dealer_id", data.data.dealer_id);

      if (rememberMe) {
        Cookies.set("email", email, { expires: 30 });
        Cookies.set("password", password, { expires: 30 });
        Cookies.set("token", token, { expires: 30 });
        Cookies.set("userId", id.toString(), { expires: 30 });
        Cookies.set("userName", name, { expires: 30 });
        Cookies.set("rememberMe", "true", { expires: 30 });
      } else {
        Cookies.remove("email");
        Cookies.remove("password");
        Cookies.remove("token");
        Cookies.remove("userId");
        Cookies.remove("userName");
        Cookies.remove("rememberMe");
      }

      navigate("/");
    } catch (error) {}
  };

  return (
    <>
      <form className="row g-3 w-100" onSubmit={handleLogin}>
        <div className="FormBox">
          <div className="position-relative">
            <InputField
              InputType="email"
              InputName="email"
              Inputplaceholder="User ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {error.email && <p className="Login-Error-msg">{error.email}</p>}
          </div>
          <div className="input-group position-relative">
            <InputField
              InputBoxClassname="input-group-append d-grid"
              InputType={showPassword ? "text" : "password"}
              InputName="password"
              Inputplaceholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <button
              className="btn Eye_Button"
              type="button"
              onClick={() => {
                playClickSound();
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? (
                <FiEyeOff className="clickTarget" />
              ) : (
                <FiEye
                  className="clickTarget"
                  onClick={() => {
                    playClickSound();
                  }}
                />
              )}
            </button>
            {error.password && (
              <p className="Login-Error-msg">{error.password}</p>
            )}
          </div>

          <div className="col-md-12 Remember_Forgot">
            <div
              className="Remember_Box"
              onClick={() => {
                playClickSound();
              }}
            >
              <InputField
                InputType="checkbox"
                InputName="rememberMe"
                customClassName="form-check-input"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <span>Remember Me</span>
            </div>
            <div>
              <NavLink to="/SendCode" onClick={playClickSound}>
                Forgot Password?
              </NavLink>
            </div>
          </div>
          <div className="col-md-12 Sign_In_Box">
            <MyButton title="Sign in" className="w-100 MyBtn" type="submit" />
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
