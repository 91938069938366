import React, { useState, useEffect, useRef } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { FiMicOff, FiMic } from "react-icons/fi";
import { IoSend } from "react-icons/io5";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useNavigate } from "react-router-dom";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useLanguage } from "../../../../LanguageContext";

import useClipboard from "react-use-clipboard";
import SearchData from "./DashboardRightSidebarSearchData";
import FAQComponent from "./FAQComponent";
import AccordionData from "./Accordion_Questions_Answers.json";
import translations from "../../../../translations.json";

import $ from "jquery";
import axios from "axios";

const DashboardRightSidebar = ({ setIsLogin, isModalOpen, onSidebarLoad }) => {
  const [transcription, setTranscription] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [isQuerying, setIsQuerying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [VideoLiveLink, setVideoLiveLink] = useState();
  const [newVideoUrl, setNewVideoUrl] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);

  const { language } = useLanguage();
  const navigate = useNavigate();
  const chatBoxRef = useRef(null);
  const pressTimerRef = useRef(null);
  const answersEndRef = useRef(null);
  const recorderControls = useAudioRecorder();
  const usertoken = localStorage.getItem("token");

  // Initialize the S3 clientc **********

  useEffect(() => {
    async function generatePresignedUrl() {
      const s3Client = new S3Client({
        region: "eu-north-1",
        credentials: {
          accessKeyId: "AKIA4MTWI66ISAL6HYNS",
          secretAccessKey: "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",
        },
      });

      const bucketName = "versasecondarydbvideos";
      const objectKey = VideoLiveLink;

      const command = new GetObjectCommand({
        Bucket: bucketName,
        Key: objectKey,
      });

      try {
        const url = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
        setNewVideoUrl(url);
      } catch (error) {
        console.error("Error generating presigned URL:", error);
      }
    }

    generatePresignedUrl();
  }, [VideoLiveLink]);

  // Initialize the S3 client code end *********

  const addAudioElement = (blob) => {
    const formData = new FormData();
    formData.append("audio", blob, "audio.wav");
    setTimeout(() => {
      setIsInputFocused(true);
    }, 100);
    setIsLoading(true);

    axios
      .post("https://api.menuverse.ai/transcribe2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: "Bearer " + usertoken,
        },
      })
      .then((response) => {
        setTranscription(response.data.text);
        setIsLoading(false);
        if (!response.ok) {
          // localStorage.clear();
          if (response.status === 401 || response.status === 403) {
            localStorage.clear();
            setIsLogin(false);
            navigate("/login");
          }
          throw new Error(`Error: ${response.status}`);
        }
        return response.json();
      })
      .catch((error) => {
        // console.error("Error during transcription:", error);
        setIsLoading(false);
        // navigate("/login")
      });
  };

  const handleRecording = () => {
    if (isRecording) {
      startConverting();
      recorderControls.stopRecording();
    } else {
      startConverting();
      recorderControls.startRecording();
    }
    setIsRecording(!isRecording);
  };

  const handlePointerDown = () => {
    // Start a timer for long press
    pressTimerRef.current = setTimeout(() => {
      if (!isListening) {
        startListening();
        setIsListening(true); // Set listening state
      }
      if (!isRecording) {
        recorderControls.startRecording(); // Start recording
        setIsRecording(true); // Mark recording as active
      }
    }, 500); // Adjust the long press duration as necessary (500ms)
  };

  const handlePointerUp = (e) => {
    // Clear the long press timer
    stopListening(); // Stop listening
    clearTimeout(pressTimerRef.current);

    if (isRecording) {
      recorderControls.stopRecording(); // Stop recording
      setIsRecording(false); // Mark recording as inactive
    }
    setIsListening(false); // Reset listening state
  };

  const handleInputChange = (e) => {
    const InputValue = e.target.value;

    // Update transcription value
    setTranscription(InputValue);

    // Use setTimeout to set focus after a slight delay
    setIsInputFocused(true);

    // Check if input is empty (or only spaces) and set focus accordingly
    if (InputValue.trim() === "") {
      setIsInputFocused(false);
    }
  };

  const [textToCopy, setTextToCopy] = useState();
  const [isCopied, setCopied] = useClipboard(textToCopy, {
    successDuration: 1000,
  });
  const [userQuestionsAndAnswers, setUserQuestionsAndAnswers] = useState([]);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const [activeButton, setActiveButton] = useState("VSC");
  const [isListening, setIsListening] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [videoURL, setVideoURL] = useState("");
  const [ActiveIndexAccordion, setActiveIndexAccordion] = useState();
  const [prevVideoLiveLink, setPrevVideoLiveLink] = useState();
  const [receivedSameVideo, setReceivedSameVideo] = useState(1);

  const hanleInexXhnage = (index) => {
    setActiveIndexAccordion(index);
  };

  useEffect(() => {
    setFaqs(AccordionData);
  }, []);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleExpandButtonClick = function () {
      const isCurrentlyCollapsed =
        $(this).text() === translations[language].Collapse ||
        $(this).text() === "Collapse" ||
        $(this).text() === "Colapsar";

      if (isCurrentlyCollapsed) {
        $(this).text(translations[language].Expand || "Expand");
        $(this).prepend(
          '<img src="/assets/Images/DashboardImages/expand-arrowsIcon.svg" alt="">'
        );
      } else {
        $(this).text(translations[language].Collapse || "Collapse");
        $(this).prepend(
          '<img src="/assets/Images/DashboardImages/expand-arrowsCollaps-Icon.svg" alt="">'
        );
      }

      $(".Dashboard-right-Sidebar").toggleClass("Expanded");
    };

    // Update button text and icon when language or isExpanded changes
    const buttonText = isExpanded
      ? translations[language].Collapse || "Collapse"
      : translations[language].Expand || "Expand";

    $("#ExpandButton")
      .text(buttonText)
      .prepend(
        `<img src="/assets/Images/DashboardImages/${
          isExpanded ? "expand-arrowsCollaps-Icon.svg" : "expand-arrowsIcon.svg"
        }" alt="">`
      );

    // Remove and reattach click event to avoid duplicate bindings
    $("#ExpandButton").off("click", handleExpandButtonClick);
    $("#ExpandButton").on("click", handleExpandButtonClick);

    // Cleanup on unmount
    return () => {
      $("#ExpandButton").off("click", handleExpandButtonClick);
    };
  }, [language, isExpanded]);

  useEffect(() => {
    const inputField = document.getElementById("styled-placeholder-input");

    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSend();
      }
    };

    inputField.addEventListener("keydown", handleKeyDown);

    return () => {
      inputField.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValue, transcription]);

  const startListening = () =>
    SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
  const stopListening = () => {
    SpeechRecognition.stopListening();
    const correctedTranscript = autoCorrectTranscript(transcript);
    setTextToCopy(correctedTranscript);
    setCopied();
  };

  const startConverting = () => {
    if (isListening) {
      stopListening();
    } else {
      startListening();
    }
    setIsListening(!isListening);
  };
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevents the default context menu from showing up on long press
  };

  useEffect(() => {
    if (answersEndRef.current) {
      answersEndRef.current.scrollTop = answersEndRef.current.scrollHeight;
    }
  }, [userQuestionsAndAnswers]);

  const handleSend = () => {
    setTimeout(() => {
      setIsInputFocused(false);
    }, 100);
    if (!inputValue.trim() && !transcription.trim()) {
      return;
    }

    if (isQuerying) {
      return;
    }

    setIsQuerying(true);
    onSend();
    const Dealid = parseInt(window.location.pathname.split("/")[2], 10);
    const Storestate = localStorage.getItem("state");

    const data = {
      query: inputValue || transcription,
      dealId: Dealid.toString(),
      state: Storestate,
      language: language,
    };

    // Add the new question to the state with a fetching status
    setUserQuestionsAndAnswers((prev) => [
      ...prev,
      { question: data.query, answer: "", fetching: true },
    ]);

    setInputValue("");
    setTranscription("");
    resetTranscript();

    fetch("https://versa.ampleteck.com/userQuery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token":
          "4$4V7r^!7{Rz3}7[=)M{f#y!&5>rTq6eS[t&kx)Uv%9*R*72;X8R*tJ,>3MBc(eW}D==]5x@qMX?84gP?kZx;T8E42h)P3G!p4#7",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        const videoPath = data.video_path;

        // Log whenever the video path changes or remains the same
        if (videoPath !== prevVideoLiveLink) {
          setPrevVideoLiveLink(videoPath); // Update the previous video path
          setVideoLiveLink(videoPath); // Update the current video path
          setReceivedSameVideo(receivedSameVideo + 1);
        } else {
          // If the same video path is returned
          // console.log("Received same video path:", videoPath);
          setReceivedSameVideo(receivedSameVideo + 1);
        }

        setUserQuestionsAndAnswers((prev) =>
          prev.map((item) =>
            item.question === data.query
              ? { ...item, answer: data.llm_output, fetching: false }
              : item
          )
        );
        setIsQuerying(false);
        setVideoLiveLink(videoPath);
      })
      .catch((error) => {
        console.error("Error:", error);
        setUserQuestionsAndAnswers((prev) =>
          prev.map((item) =>
            item.question === data.query ? { ...item, fetching: false } : item
          )
        );
        setIsQuerying(false);
      });
  };

  const onSend = () => {
    setMessageSent((prev) => !prev);
  };

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messageSent]);

  const autoCorrectTranscript = (text) => {
    let correctedText = text.split(" ").join(" ");

    correctedText = correctedText.replace(
      /versa|vursa|virsa|verssa/gi,
      "Versa"
    );

    return correctedText;
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Browser does not support speech recognition.</div>;
  }

  return (
    <div
      className="col-lg-3 Dashboard-right-Sidebar"
      onContextMenu={handleContextMenu}
      style={{
        userSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
        MozUserSelect: "none",
      }}
    >
      <div className="Main_Right_Sidebar">
        {/* <div> */}
        <div className="RightHeader">
          <img
            className="Black-Ask-versa"
            onLoad={() => {
              onSidebarLoad();
            }}
            src="/assets/Images/DashboardImages/AskVersaFrame.png"
            alt=""
          />
          <img
            className="White-Ask-versa"
            onLoad={() => {
              onSidebarLoad();
            }}
            src="/assets/Images/DashboardImages/AskVersaFrame-White.png"
            alt=""
          />
          <button id="ExpandButton" onClick={handleToggle}>
            <img
              src="/assets/Images/DashboardImages/expand-arrowsIcon.svg"
              alt=""
            />
            {isExpanded
              ? translations[language].Collapse || "Collapse"
              : translations[language].Expand || "Expand"}
          </button>
        </div>

        <div className="AccordionAndButtonsParent" ref={answersEndRef}>
          <div className="RightSideBarBody">
            <div className="accordion" id="accordionParent">
              <div className="accordion-item">
                <h2 className="accordion-header accordionParentButton">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseParent"
                    aria-expanded="true"
                    aria-controls="collapseParent"
                  >
                    {translations[language].FAQText}
                  </button>
                </h2>
                <div
                  id="collapseParent"
                  className="accordion-collapse collapse"
                >
                  <div className="accordion" id="accordionChild">
                    <FAQComponent
                      faqs={faqs}
                      activeButton={activeButton}
                      setVideoURL={setVideoURL}
                      onInexChange={hanleInexXhnage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="Right-SideBar-Body-Bottom">
              <SearchData
                setActiveButton={setActiveButton}
                activeButton={activeButton}
                videoURL={videoURL}
                ActiveIndexAccordion={ActiveIndexAccordion}
                setActiveIndexAccordion={setActiveIndexAccordion}
                VideoLiveLink={VideoLiveLink}
                isModalOpen={isModalOpen}
                receivedSameVideo={receivedSameVideo}
                newVideoUrl={newVideoUrl}
              />
            </div>
            <div className="ChatBox_Api" ref={chatBoxRef}>
              {userQuestionsAndAnswers.map((item, index) => (
                <div key={index}>
                  <div className="My_Qoustion">
                    <p>{item.question}</p>
                  </div>
                  <div className="Api_Response">
                    {item.fetching ? (
                      <div className="New-chat-loader"></div>
                    ) : (
                      <p>{item.answer}</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="RightFooter">
            {/* <input type="text" placeholder=" " id="styled-placeholder-input" value={isLoading ? "Loading..." : inputValue || transcription} onChange={handleInputChange} onFocus={handleInputFocus} onBlur={handleInputBlur} disabled={isLoading} /> */}

            <textarea
              placeholder=" "
              id="styled-placeholder-input"
              value={isLoading ? "Loading..." : transcription}
              onChange={handleInputChange}
              disabled={isLoading}
              rows={1}
            />

            <div className="placeholder-wrapper">
              {isListening && !transcription ? (
                <>
                  <span className="first-letter">
                    {translations[language].Recording}{" "}
                  </span>
                </>
              ) : (
                !transcription &&
                !isLoading && (
                  <>
                    <span className="first-letter">Ask </span> VERSA
                  </>
                )
              )}
            </div>

            <div className="Mike_And_Send_Btn">
              <button
                onPointerDown={(e) => {
                  e.preventDefault();
                  handlePointerDown();
                }}
                onPointerUp={(e) => {
                  handlePointerUp();
                }}
                className={` Mic-Btn-For-Mobile ${isListening ? "active" : ""}`}
              >
                {isListening ? <FiMic /> : <FiMicOff />}
              </button>
              {isInputFocused && (
                <button className="MyBtn" onClick={handleSend}>
                  <IoSend />
                </button>
              )}
            </div>
          </div>

          <div className="d-none">
            <AudioRecorder
              onRecordingComplete={addAudioElement}
              recorderControls={recorderControls}
            />
            <button onClick={handleRecording}>
              {isRecording ? "Stop Recording" : "Start Recording"}
            </button>
            <div>
              <h3>Transcription:</h3>
              <input
                type="text"
                value={transcription}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        {/* </div> */}
      </div>
    </div>
  );
};

export default DashboardRightSidebar;
